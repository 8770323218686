import { Avatar, Box, Card, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { CardHeader, CardMedium } from '../../app/AppStyles';
import FlagDisplay from '../flags/FlagDisplay';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import ProgressActivities from './ProgressActivities';
import ProgressBar from './ProgressBar';
import ProgressProgramDisplay from './ProgressProgramDisplay';
import ProgressRemarks from './ProgressRemarks';
import { fetchUserProgress } from './progressSlice';

import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';

export default function ProgressPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userProgress, userProgressStatus } = useSelector((state) => state.progress);
    const { activeDepartment, activeThemeSettings, departmentThemesStatus } = useSelector((state) => state.settings.theme);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { student } = useSelector((state) => state.student);
    const { deleteStatus } = useSelector((state) => state.register);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    const [programStatus, setProgramStatus] = useState(false);

    useEffect(() => {
        setProgramStatus(userProgress?.totalPoints?.achievedPoints >= userProgress?.totalPoints?.minPoints && (userProgress?.requiredPoints?.anyRequiredPrograms && userProgress?.requiredPoints?.requiredPointsStatus === "green") && (userProgress?.progressFlags?.lifestyleStatus === 2))
    }, [userProgress]);

    useEffect(() => {
        if (student !== null) {
            if (siomSettings.useMultipleDepartments) {
                if (activeDepartment !== null) {
                    dispatch(fetchUserProgress({ departmentId: activeDepartment[0].id, fullProgress: true }));
                }
            } else {
                dispatch(fetchUserProgress({ departmentId: null, fullProgress: true }));
            };
        };
    }, [dispatch, student, activeDepartment, deleteStatus, departmentThemesStatus, siomSettings]);

    const overviewClick = () => {
        navigate("/voortgang-overzicht");
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.PROGRESS} content={
            <ContentLoader successCondition={userProgressStatus === "success"} errorCondition={userProgressStatus === "failed"} content={
                <Grid container spacing={1}>
                    {programStatus && <Grid size={{ xs: 12 }} sx={{ display: 'flex' }}>
                        <Card elevation={0} sx={{ ...CardMedium, display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', color: 'success.main' }}>
                                {resourceFinal[appLanguage].messages.PROGRESS_PROGRAM_COMPLETED}
                            </Typography>
                        </Card>
                    </Grid>}
                    <Grid size={{ xs: 12, md: activeThemeSettings.flagsEnabled ? 8 : 12 }} sx={{ display: 'flex' }}>
                        <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', flex: '1 1 auto', }}>
                                <Box sx={{ flex: '1 1 auto' }}>
                                    <ProgressBar progressPoints={userProgress?.totalPoints} legendDisplay={true} barHeight={180} />
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    {activeThemeSettings.flagsEnabled &&
                        <Grid size={{ xs: 12, md: 4 }} sx={{ display: 'flex' }}>
                            <Card elevation={0} sx={{ ...CardMedium }}>
                                <FlagDisplay progressFlags={userProgress?.progressFlags} requiredPoints={userProgress?.requiredPoints} xsIconSize={60} mdIconSize={60} compact={false} />
                            </Card>
                        </Grid>
                    }
                    <Grid size={{ xs: 12 }} sx={{ display: 'flex' }}>
                        <Card elevation={0} sx={{ ...CardMedium }}>
                            <Typography variant="h6" gutterBottom sx={{ ...CardHeader }}>{resourceFinal[appLanguage].messages.CURRENT_CLINICS}</Typography>
                            <ProgressActivities progressActivities={userProgress?.progressActivities} />
                        </Card>
                    </Grid>
                    {userProgress?.progressRemarks?.remarksCount > 0 &&
                        <Grid size={{ xs: 12 }} sx={{ display: 'flex' }}>
                            <Card elevation={0} sx={{ ...CardMedium }}>
                                <Typography variant="h6" gutterBottom sx={{ ...CardHeader }}>{resourceFinal[appLanguage].words.REMARKS} ({userProgress?.progressRemarks.remarksCount})</Typography>
                                <ProgressRemarks remarks={userProgress?.progressRemarks.remarks} />
                            </Card>
                        </Grid>
                    }
                    <Grid size={{ xs: 12 }}>
                        <ProgressProgramDisplay progressProgram={userProgress?.progressProgram} />
                    </Grid>
                </Grid>
            } />
        } contentRight={
            siomSettings.useMultipleDepartments &&
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <Tooltip title={`${resourceFinal[appLanguage].words.TOTAL} ${resourceFinal[appLanguage].words.PROGRESS} ${resourceFinal[appLanguage].words.OVERVIEW}`} placement="left" arrow>
                    <IconButton sx={{ alignSelf: 'flex-end' }} onClick={overviewClick}>
                        <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                            <StackedBarChartIcon sx={{ color: 'white.main', fontSize: 40 }} />
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
        } bottomBar contentBottom={
            siomSettings.useMultipleDepartments &&
            <IconButton sx={{ alignSelf: 'flex-end' }} onClick={overviewClick}>
                <StackedBarChartIcon sx={{ color: 'white.main', p: 0.5 }} />
            </IconButton>
        } />
    );
}
