import { Box, Card, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { CardMedium, HomeTileSmall } from '../../app/AppStyles';

export default function ProgramStatusTile() {

    const { userProgress } = useSelector((state) => state.progress);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [programStatus, setProgramStatus] = useState(false);

    useEffect(() => {
        setProgramStatus(userProgress?.totalPoints?.achievedPoints >= userProgress?.totalPoints?.minPoints && (userProgress?.requiredPoints?.anyRequiredPrograms && userProgress?.requiredPoints?.requiredPointsStatus === "green") && (userProgress?.progressFlags?.lifestyleStatus === 2))
    }, [userProgress]);

    return (
        <>
            {programStatus &&
                <Box sx={{ ...HomeTileSmall, width: 1 }}>
                    <Box sx={{ ...CardMedium, width: 1, height: 1, boxSizing: 'border-box' }}>
                        <Card elevation={0} sx={{ ...CardMedium, display: 'flex', justifyContent: 'center' }}>
                            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: { xs: '0.8em', md: '1em' }, color: 'success.main' }}>
                                {resourceFinal[appLanguage].messages.PROGRESS_PROGRAM_COMPLETED}
                            </Typography>
                        </Card>
                    </Box>
                </Box>
            }
        </>
    );
}
