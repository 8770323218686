import { Avatar, Box, Button, ButtonGroup, Checkbox, FormControlLabel, IconButton, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import ContentLoader from '../../subcomponents/ContentLoader';
import FilterSidebar from '../../subcomponents/FilterSidebar';
import PageLayout from '../../subcomponents/PageLayout';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import PlanningPerformanceEditCard from '../performances/PlanningPerformanceEditCard';
import { resetDefaultActivityValues, resetPlanningPerformanceStatus, selectPerformance, setFailedPerformance } from '../performances/planningPerformanceSlice';
import { fetchDayPlanning, fetchMonthPlanning, fetchPeriodPlanning, fetchPlanningActivities, fetchPlanningPeriods, fetchWeekPlanning, fetchYearPlanning, setAccordionDisplay, setAccordionShowPast } from '../planningSlice';
import PlanningActivityAccordion from './PlanningActivityAccordion';
import PlanningDateAccordion from './PlanningDateAccordion';

import AddIcon from '@mui/icons-material/Add';
import FilterButton from '../../subcomponents/FilterButton';

export default function PlanningAccordionDisplay() {
    const dispatch = useDispatch();
    const today = new Date();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { deletePerformanceStatus } = useSelector((state) => state.planning.performance);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const {
        planningActivities,
        planningActivitiesStatus,
        dayPlanningStatus,
        weekPlanningStatus,
        monthPlanningStatus,
        yearPlanningStatus,
        periodPlanning,
        periodPlanningStatus,
        accordionDisplay,
        accordionShowPast,
        planningPeriods,
        planningPeriodsStatus
    } = useSelector((state) => state.planning.planning);

    const [open, setOpen] = useState(false);

    const handleClick = (type) => { dispatch(setAccordionDisplay(type)) };

    const callbackModal = () => {
        setOpen(false);
        dispatch(resetPlanningPerformanceStatus());
        dispatch(resetDefaultActivityValues());
        dispatch(setFailedPerformance(false));
    };

    const newClick = () => {
        dispatch(selectPerformance({}));
        setOpen(true);
    };

    useEffect(() => {
        if (siomSettings.useMultipleDepartments && activeDepartment)
            dispatch(fetchPlanningActivities({ showPast: accordionShowPast, departmentId: activeDepartment[0]?.id }));

        if (!siomSettings.useMultipleDepartments)
            dispatch(fetchPlanningActivities({ showPast: accordionShowPast, departmentId: null }));

        dispatch(fetchPlanningPeriods());
    }, [dispatch, accordionShowPast, activeDepartment]);

    useEffect(() => {
        if (deletePerformanceStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetPlanningPerformanceStatus());

            const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

            if (accordionDisplay === "AccordionActivity")
                dispatch(fetchPlanningActivities({ showPast: accordionShowPast, departmentId: departmentId }));

            if (accordionDisplay === "AccordionDay")
                dispatch(fetchDayPlanning({ date: today.toISOString(), departmentId: departmentId }));

            if (accordionDisplay === "AccordionWeek")
                dispatch(fetchWeekPlanning({ date: today.toISOString(), departmentId: departmentId }));

            if (accordionDisplay === "AccordionMonth")
                dispatch(fetchMonthPlanning({ date: today.toISOString(), departmentId: departmentId }));

            if (accordionDisplay === "AccordionYear")
                dispatch(fetchYearPlanning({ date: null, departmentId: departmentId }));
            if (accordionDisplay === "AccordionPeriod" && planningPeriodsStatus === "success")
                dispatch(fetchPeriodPlanning({ periodId: planningPeriods[0].periodId, departmentId: departmentId }));
        } else if (deletePerformanceStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetPlanningPerformanceStatus());
        };
    }, [deletePerformanceStatus]);

    const FilterFragment = () => {
        return (
            <>
                <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={accordionShowPast} onChange={() => {
                    dispatch(setAccordionShowPast(!accordionShowPast))
                }} />} label={`${resourceFinal[appLanguage].messages.SHOW_PAST_PERFORMANCES}`} />
                <Typography variant="h6" sx={{ ...PageHeader, m: 0, p: 0 }}>{resourceFinal[appLanguage].words.DISPLAY}</Typography>
                <ButtonGroup orientation="vertical" variant="text" sx={{ m: 0, p: 0 }}>
                    <Button onClick={() => {
                        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

                        handleClick("AccordionActivity")
                        dispatch(fetchPlanningActivities({ showPast: accordionShowPast, departmentId: departmentId }));
                    }}>{resourceFinal[appLanguage].words.ACTIVITY}</Button>
                    <Button onClick={() => {
                        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

                        handleClick("AccordionDay")
                        dispatch(fetchDayPlanning({ date: today.toISOString(), departmentId: departmentId }));
                    }}>{resourceFinal[appLanguage].words.DAY}</Button>
                    <Button onClick={() => {
                        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

                        handleClick("AccordionWeek")
                        dispatch(fetchWeekPlanning({ date: today.toISOString(), departmentId: departmentId }));
                    }}>{resourceFinal[appLanguage].words.WEEK}</Button>
                    <Button onClick={() => {
                        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

                        handleClick("AccordionMonth")
                        dispatch(fetchMonthPlanning({ date: today.toISOString(), departmentId: departmentId }));
                    }}>{resourceFinal[appLanguage].words.MONTH}</Button>
                    <Button onClick={() => {
                        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

                        handleClick("AccordionYear")
                        dispatch(fetchYearPlanning({ date: today.toISOString(), departmentId: departmentId }));
                    }}>{resourceFinal[appLanguage].words.YEAR}</Button>
                    <Button onClick={() => {
                        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

                        handleClick("AccordionPeriod")
                        dispatch(fetchPeriodPlanning({ periodId: planningPeriods[0].periodId, departmentId: departmentId }));
                    }}>{resourceFinal[appLanguage].words.PERIOD}</Button>
                </ButtonGroup>
            </>
        );
    };

    return (
        <PageLayout title={resourceFinal[appLanguage].words.PLANNING} content={
            <>
                <Box sx={{ display: 'flex', flex: '1 1 auto' }}>
                    {accordionDisplay === "AccordionActivity" &&
                        <ContentLoader successCondition={planningActivitiesStatus === "success"} errorCondition={planningActivitiesStatus === "failed"} content={
                            <PlanningActivityAccordion title={resourceFinal[appLanguage].words.ACTIVITY} content={planningActivities} amount={10} />
                        } />}
                    {accordionDisplay === "AccordionDay" &&
                        <ContentLoader successCondition={dayPlanningStatus === "success"} errorCondition={dayPlanningStatus === "failed"} content={
                            <PlanningDateAccordion title={resourceFinal[appLanguage].words.DAY} amount={10} type={"day"} />
                        } />}
                    {accordionDisplay === "AccordionWeek" &&
                        <ContentLoader successCondition={weekPlanningStatus === "success"} errorCondition={weekPlanningStatus === "failed"} content={
                            <PlanningDateAccordion title={resourceFinal[appLanguage].words.WEEK} amount={10} type={"week"} />
                        } />}
                    {accordionDisplay === "AccordionMonth" &&
                        <ContentLoader successCondition={monthPlanningStatus === "success"} errorCondition={monthPlanningStatus === "failed"} content={
                            <PlanningDateAccordion title={resourceFinal[appLanguage].words.MONTH} amount={10} type={"month"} />
                        } />}
                    {accordionDisplay === "AccordionYear" &&
                        <ContentLoader successCondition={yearPlanningStatus === "success"} errorCondition={yearPlanningStatus === "failed"} content={
                            <PlanningDateAccordion title={resourceFinal[appLanguage].words.YEAR} amount={10} type={"year"} />
                        } />}
                    {accordionDisplay === "AccordionPeriod" &&
                        <ContentLoader successCondition={periodPlanningStatus === "success"} errorCondition={periodPlanningStatus === "failed"} content={
                            <PlanningDateAccordion title={resourceFinal[appLanguage].words.PERIOD} amount={10} type={"period"} />
                        } />}
                </Box>
                <Modal open={open} onClose={callbackModal}>
                    <>
                        <PlanningPerformanceEditCard handleClose={callbackModal} addNew addActivity waitForInput periodId={(accordionDisplay === "AccordionPeriod" && periodPlanningStatus === "success") && periodPlanning?.period?.periodId} />
                    </>
                </Modal>
            </>
        } bottomBar contentLeft={
            <FilterSidebar filters={<FilterFragment />} />
        } contentRight={
            <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                <IconButton sx={{ alignSelf: 'flex-end' }} onClick={newClick}>
                    <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                        <AddIcon sx={{ color: 'white.main', fontSize: 40 }} />
                    </Avatar>
                </IconButton>
            </Box>
        }
            contentBottom={
                <FilterButton filters={<FilterFragment />} />}
        />
    );
}
