import { Box, Button, Checkbox, FormControl, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableHeader } from '../../app/AppStyles';
import { fetchNavSettings } from '../settings/resourceSettingsSlice';
import ContentLoader from '../subcomponents/ContentLoader';
import useSearchDebounce from '../subcomponents/SearchDebounce';
import TablePaginationActions from '../subcomponents/TablePaginationActions';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../subcomponents/alertSlice';
import { fetchAllNavSettings, putAllNavSettings, resetUpdateStatus, setNavItemOrder, setNavNavigation, setNavTile, setNavTileButton, setNavToggled, setNavUserRole } from './adminSlice';

export default function NavSettingsTab() {
    const dispatch = useDispatch();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { navSettingsStatus, updateNavSettingsStatus } = useSelector((state) => state.admin);

    // Get all nav settings
    useEffect(() => {
        dispatch(fetchAllNavSettings());
    }, [dispatch]);

    // Put Callback
    useEffect(() => {
        if (updateNavSettingsStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetUpdateStatus());
            dispatch(fetchNavSettings());
        } else if (updateNavSettingsStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].admin.SETTING_CHANGED_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetUpdateStatus());
        };
    }, [updateNavSettingsStatus]);

    return (
        <ContentLoader successCondition={navSettingsStatus === "success"} errorCondition={navSettingsStatus === "failed"} content={
            <Box sx={{ width: 1 }}>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" padding="checkbox" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].admin.NAV_TOGGLED}</TableCell>
                                <TableCell align="left" sx={{ ...TableHeader }} width="200">{resourceFinal[appLanguage].words.NAME}</TableCell>
                                <TableCell align="left" sx={{ ...TableHeader }} width="200">{resourceFinal[appLanguage].admin.NAV_ROLE}</TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].admin.NAV_ORDER}</TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].admin.NAV_NAVIGATION}</TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].admin.NAV_TILE}</TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].admin.NAV_TILE_BUTTON}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableFragment />
                    </Table>
                </TableContainer>
            </Box>
        } />
    );
}

const TableFragment = () => {
    const dispatch = useDispatch();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { navSettings, navChanged } = useSelector((state) => state.admin);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(navSettings?.length < 100 ? -1 : 100);
    const [newItemOrder, setNewItemOrder] = useSearchDebounce()

    useEffect(() => {
        if (newItemOrder)
            dispatch(setNavItemOrder(newItemOrder));
    }, [newItemOrder]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - navSettings?.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSaveClick = () => {
        dispatch(putAllNavSettings(navSettings));
    };

    return (
        <>
            <TableBody>
                {(rowsPerPage > 0 ? navSettings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : navSettings).map(item =>
                    <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' } }}>
                        <TableCell align="center" padding="checkbox">
                            <Checkbox checked={item.toggled} onChange={(e) => dispatch(setNavToggled({ id: item.id, value: e.target.checked }))} />
                        </TableCell>
                        <TableCell align="left" width="200">{item.name}</TableCell>
                        <TableCell align="left" width="200">
                            <FormControl fullWidth>
                                <Select
                                    value={item.userRole} disabled={!item.toggled}
                                    onChange={(e) => dispatch(setNavUserRole({ id: item.id, value: e.target.value }))}
                                    variant="standard"
                                >
                                    <MenuItem value={"All"}>{resourceFinal[appLanguage].admin.NAV_SELECT_ALL}</MenuItem>
                                    <MenuItem value={"Activitymaster"}>{resourceFinal[appLanguage].admin.NAV_SELECT_ACT_MASTER}</MenuItem>
                                    <MenuItem value={"Admin"}>{resourceFinal[appLanguage].admin.NAV_SELECT_ADMIN}</MenuItem>
                                    <MenuItem value={"Employee"}>{resourceFinal[appLanguage].admin.NAV_SELECT_EMPLOYEE}</MenuItem>
                                    <MenuItem value={"Employeemanager"}>{resourceFinal[appLanguage].admin.NAV_SELECT_EMP_MASTER}</MenuItem>
                                    <MenuItem value={"Planner"}>{resourceFinal[appLanguage].admin.NAV_SELECT_PLANNER}</MenuItem>
                                    <MenuItem value={"Reporter"}>{resourceFinal[appLanguage].admin.NAV_SELECT_REPORTER}</MenuItem>
                                    <MenuItem value={"Student"}>{resourceFinal[appLanguage].admin.NAV_SELECT_STUDENT}</MenuItem>
                                    <MenuItem value={"Webmaster"}>{resourceFinal[appLanguage].admin.NAV_SELECT_WEB_MASTER}</MenuItem>
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell align="center">
                            <TextField
                                fullWidth type="number" disabled={!item.toggled}
                                defaultValue={item.itemOrder}
                                inputProps={{ min: 0, max: navSettings?.length }}
                                onChange={(e) => setNewItemOrder({ id: item.id, value: e.target.value })}
                                variant="standard"
                            />
                        </TableCell>
                        <TableCell align="center">
                            <Checkbox disabled={!item.toggled} checked={item.navigation} onChange={(e) => dispatch(setNavNavigation({ id: item.id, value: e.target.checked }))} />
                        </TableCell>
                        <TableCell align="center">
                            <Checkbox disabled={!item.toggled} checked={item.tile} onChange={(e) => dispatch(setNavTile({ id: item.id, value: e.target.checked }))} />
                        </TableCell>
                        <TableCell align="center">
                            <Checkbox disabled={!item.toggled} checked={item.tileButton} onChange={(e) => dispatch(setNavTileButton({ id: item.id, value: e.target.checked }))} />
                        </TableCell>
                    </TableRow>
                )}
                {(rowsPerPage > 0 ? navSettings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : navSettings).length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <td>
                        <Button disabled={!navChanged} variant="outlined" onClick={handleSaveClick} sx={{ my: 1 }}>
                            {resourceFinal[appLanguage].words.SAVE}
                        </Button>
                    </td>
                    <TablePagination
                        sx={{ border: 0 }}
                        rowsPerPageOptions={[5, 10, 25,
                            {
                                label: navSettings?.length < 100 ? resourceFinal[appLanguage].words.ALL : '100',
                                value: navSettings?.length < 100 ? -1 : 100
                            }
                        ]}
                        count={navSettings?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                        labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                    />
                </TableRow>
            </TableFooter>
        </>
    );
};
