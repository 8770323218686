import { Box, Button, Card, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchStudentHistory, fetchSearchStudentProgramHistory } from '../search/student/searchStudentSlice';
import { fetchStudentHistory, fetchStudentProgramHistory } from './historySlice';
import ContentLoader from '../subcomponents/ContentLoader';
import { CardMedium, TableHeader } from '../../app/AppStyles';
import { GetDate } from '../../app/AppUtils';
import ProgressBar from '../progress/ProgressBar';
import ProgressActivities from '../progress/ProgressActivities';
import Scrollbars from 'react-custom-scrollbars-2';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: 'auto',
    maxHeight: '90%',
    maxWidth: '90%',
    boxShadow: 24,
};

export default function HistoryDisplay({ studentId, employeeMode }) {
    const dispatch = useDispatch();

    const { searchStudentHistory, searchStudentHistoryStatus, searchStudentProgramHistory, searchStudentProgramHistoryStatus } = useSelector((state) => state.search.student);
    const { studentHistory, studentHistoryStatus, studentProgramHistory, studentProgramHistoryStatus } = useSelector((state) => state.history);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [selectedHistory, setSelectedHistory] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [historyContent, setHistoryContent] = useState();
    const [programContent, setProgramContent] = useState();

    useEffect(() => {
        if (studentHistoryStatus === "success")
            setHistoryContent(studentHistory);

        if (searchStudentHistoryStatus === "success" && employeeMode)
            setHistoryContent(searchStudentHistory);
    }, [studentHistoryStatus, searchStudentHistoryStatus, employeeMode]);

    useEffect(() => {
        if (studentProgramHistoryStatus === "success")
            setProgramContent(studentProgramHistory);

        if (searchStudentProgramHistoryStatus === "success" && employeeMode)
            setProgramContent(searchStudentProgramHistory);
    }, [searchStudentProgramHistoryStatus, studentProgramHistoryStatus, employeeMode]);

    useEffect(() => {
        if (studentId) {
            var departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

            if (employeeMode)
                dispatch(fetchSearchStudentHistory({ studentId: studentId, departmentId: departmentId }));
            else
                dispatch(fetchStudentHistory({ studentId: studentId, departmentId: departmentId }));
        }
    }, [dispatch, studentId]);

    useEffect(() => {
        if (historyContent) {
            handleHistoryClick(historyContent[0]?.programId);
        }
    }, [historyContent]);

    const handleHistoryClick = (programId) => {
        if (programId) {
            setSelectedHistory(programId);
            var departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

            if (employeeMode)
                dispatch(fetchSearchStudentProgramHistory({ studentId: studentId, programId: programId, departmentId: departmentId }))
            else
                dispatch(fetchStudentProgramHistory({ studentId: studentId, programId: programId, departmentId: departmentId }));
        }
    }

    const handleSubscriptionsClick = (subprogramId, showAll) => {
        let filteredSubscriptions = [];

        if (showAll)
            filteredSubscriptions = programContent?.progressActivities || [];
        else
            filteredSubscriptions = programContent?.progressActivities?.filter(x => x.subprogramId === subprogramId) || [];

        setSelectedSubscriptions(filteredSubscriptions);
        setOpenModal(true);
    }

    return (
        <>
            <ContentLoader successCondition={(studentHistoryStatus === 'success' || searchStudentHistoryStatus === 'success') && historyContent} errorCondition={studentHistoryStatus === 'failed' || searchStudentHistoryStatus === 'failed'} content={
                <Card elevation={0} sx={{ ...CardMedium, pb: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PROGRAM}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.START}{resourceFinal[appLanguage].words.DATE.toLowerCase()}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.END}{resourceFinal[appLanguage].words.DATE.toLowerCase()}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ORIGIN_NAME}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ORIGIN_LEVEL}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {historyContent?.map((item, index) =>
                                        <TableRow key={index} hover onClick={
                                            () => {
                                                handleHistoryClick(item.programId);
                                                setSelectedIndex(index);
                                            }} sx={{ cursor: 'pointer', height: 40, bgcolor: selectedIndex === index && 'primary.light' }}>
                                            <TableCell align="left">{item.programName}</TableCell>
                                            <TableCell align="center">{item.startDate ? GetDate(item.startDate) : "-"}</TableCell>
                                            <TableCell align="center">{item.endDate ? GetDate(item.endDate) : "-"}</TableCell>
                                            <TableCell align="center">{item.originName || "-"}</TableCell>
                                            <TableCell align="center">{item.originLevel || "-"}</TableCell>
                                        </TableRow>
                                    )}
                                    {historyContent?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.TABLE_RESULT_EMPTY}</TableCell></TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    {selectedHistory &&
                        <ContentLoader successCondition={(studentProgramHistoryStatus === "success" || searchStudentProgramHistoryStatus === 'success') && programContent} errorCondition={studentProgramHistoryStatus === "failed" || searchStudentProgramHistoryStatus === 'failed'} content={
                            <Grid container sx={{ width: 1, mt: 2 }} spacing={1}>
                                <Grid size={{ xs: 12 }}>
                                    <Card elevation={0} sx={{ p: 1 }}>
                                        <Grid container size={{ xs: 12 }}>
                                            <Grid size={{ xs: 12 }}>
                                                <Typography variant="h6">{resourceFinal[appLanguage].words.PROGRAM}: {programContent?.progressProgram?.programName}</Typography>
                                            </Grid>
                                            <Grid size={{ xs: 12, md: 10 }}>
                                                <ProgressBar progressPoints={programContent?.totalPoints} />
                                            </Grid>
                                            <Grid size={{ xs: 12, md: 2 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button variant="outlined" onClick={() => handleSubscriptionsClick("", true)}>{resourceFinal[appLanguage].words.SUBSCRIPTIONS}</Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                                {programContent?.programPoints?.map((item, index) =>
                                    <Grid key={index} size={{ xs: 12 }}>
                                        <Card elevation={0} sx={{ p: 1 }}>
                                            <Grid container size={{ xs: 12 }}>
                                                <Grid size={{ xs: 12 }}>
                                                    <Typography variant="h6">{resourceFinal[appLanguage].words.SUBPROGRAM}: {item.programName}</Typography>
                                                </Grid>
                                                <Grid size={{ xs: 12, md: 10 }}>
                                                    <ProgressBar progressPoints={item} />
                                                </Grid>
                                                <Grid size={{ xs: 12, md: 2 }} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Button variant="outlined" onClick={() => handleSubscriptionsClick(item.programId, false)}>{resourceFinal[appLanguage].words.SUBSCRIPTIONS}</Button>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                )}
                            </Grid>
                        } />
                    }
                </Card>
            } />
            <Modal open={openModal} onClose={() => setOpenModal(false)}>
                <Box sx={{ ...modalStyle }}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                        <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                            <ProgressActivities progressActivities={selectedSubscriptions} employeeMode={employeeMode} historyMode />
                        </Scrollbars>
                    </Card>
                </Box>
            </Modal>
        </>
    );
}
