import { Box, Button, ButtonGroup, Card, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { ConvertoToCSharpDateTime } from '../../../app/AppUtils';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import { fetchPlanningMoments, postPlanningMoment, putPlanningMoment, resetPlanningMomentStatus } from './planningMomentsSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function PlanningMomentsEditCard({ addNew, selectedMoment, performanceId, handleClose, newMomentInfo }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { putPlanningMomentStatus, postPlanningMomentStatus } = useSelector((state) => state.planning.moments);

    const [changed, setChanged] = useState(false);
    const [newMoment, setNewMoment] = useState({});
    const [newMomentStart, setNewMomentStart] = useState();
    const [newMomentEnd, setNewMomentEnd] = useState();

    const [errorMomentStart, setErrorMomentStart] = useState(null);
    const [errorMomentEnd, setErrorMomentEnd] = useState(null);
    const [errorMomentRange, setErrorMomentRange] = useState(null);

    // Post status callback
    useEffect(() => {
        if (postPlanningMomentStatus === "success") {
            dispatch(fetchPlanningMoments(performanceId));
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ADD_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetPlanningMomentStatus());
            handleClose();
        } else if (postPlanningMomentStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_ADD_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetPlanningMomentStatus());
        }
    }, [postPlanningMomentStatus]);

    // Put status callback
    useEffect(() => {
        if (putPlanningMomentStatus === "success") {
            dispatch(fetchPlanningMoments(performanceId));
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.UPDATE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetPlanningMomentStatus());
            handleClose();
        } else if (putPlanningMomentStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.UPDATE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetPlanningMomentStatus());
        }
    }, [putPlanningMomentStatus]);

    // Set selected moment state 
    useEffect(() => {
        if (!addNew && selectedMoment) {
            setNewMoment(selectedMoment);
            setNewMomentStart(dayjs(selectedMoment.startTime));
            setNewMomentEnd(dayjs(selectedMoment.endTime));
        }
    }, [selectedMoment]);

    // Keep track of changed state 
    useEffect(() => {
        if ((!addNew && newMoment !== selectedMoment) || (addNew && Object.keys(newMoment) !== 0)) {
            setChanged(true);
        } else {
            setChanged(false);
        }
    }, [addNew, selectedMoment, newMoment]);

    // Check moment range
    useEffect(() => {
        if (Date.parse(newMomentStart) >= Date.parse(newMomentEnd)) {
            setErrorMomentRange(true);
        } else {
            setErrorMomentRange(false);
        };
    }, [newMomentStart, newMomentEnd]);

    // Add click function
    const handleAddClick = async () => {
        let tempObject = { ...newMoment }; // Needed to circumvent weird read only property error

        if (!newMomentStart) return setErrorMomentStart(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorMomentStart(null);

        if (!newMomentEnd) return setErrorMomentEnd(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorMomentEnd(null);

        if (errorMomentRange) return;

        tempObject.performanceId = newMomentInfo.performanceId;
        tempObject.locationId = newMomentInfo.locationId;
        tempObject.createdBy = `AP/${user.number}`;
        tempObject.startTime = ConvertoToCSharpDateTime(newMomentStart);
        tempObject.endTime = ConvertoToCSharpDateTime(newMomentEnd);

        dispatch(postPlanningMoment(tempObject));
    };

    // Update click function
    const handleUpdateClick = async () => {
        let tempObject = { ...newMoment }; // Needed to circumvent weird read only property error

        if (!newMomentStart) return setErrorMomentStart(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorMomentStart(null);

        if (!newMomentEnd) return setErrorMomentEnd(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorMomentEnd(null);

        if (errorMomentRange) return;

        tempObject.modifiedBy = `AP/${user.number}`;
        tempObject.startTime = ConvertoToCSharpDateTime(newMomentStart);
        tempObject.endTime = ConvertoToCSharpDateTime(newMomentEnd);

        dispatch(putPlanningMoment(tempObject));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1}>
                        <Grid size={{ xs: 12 }}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.MOMENT} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                <DateTimePicker
                                    required
                                    slotProps={{ textField: { fullWidth: true } }}
                                    label={`${resourceFinal[appLanguage].words.MOMENT}${resourceFinal[appLanguage].words.START.toLowerCase()} *`}
                                    value={newMomentStart ?? null}
                                    onChange={(value) => {
                                        setChanged(true);
                                        setNewMomentStart(dayjs(value));
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                                <DateTimePicker
                                    required
                                    slotProps={{ textField: { fullWidth: true } }}
                                    label={`${resourceFinal[appLanguage].words.MOMENT}${resourceFinal[appLanguage].words.END.toLowerCase()} *`}
                                    value={newMomentEnd ?? null}
                                    onChange={(value) => {
                                        setChanged(true);
                                        setNewMomentEnd(dayjs(value));
                                    }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <TextField
                                fullWidth multiline
                                defaultValue={selectedMoment?.remark}
                                label={resourceFinal[appLanguage].words.REMARK}
                                onChange={(event) => setNewMoment({
                                    ...newMoment,
                                    remark: event.target.value
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        {putPlanningMomentStatus === "failed" && <Grid size={{ xs: 12 }}>
                            <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                        </Grid>}
                        {(errorMomentStart || errorMomentEnd) && <Grid size={{ xs: 12 }}>
                            <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_PROPERTIES_EMPTY}
                                {errorMomentStart && <li>{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.START.toLowerCase()}</li>}
                                {errorMomentEnd && <li>{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.END.toLowerCase()}</li>}
                            </Typography>
                        </Grid>}
                        {errorMomentRange && <Grid size={{ xs: 12 }}><Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.WARNING_RANGE_MOMENTS}</Typography></Grid>}
                        <Grid size={{ xs: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                {!addNew && <Button disabled={!changed || errorMomentRange || errorMomentRange} variant="outlined" color="primary" onClick={handleUpdateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                                {addNew && <Button disabled={!changed || errorMomentRange || errorMomentRange} variant="outlined" color="primary" onClick={handleAddClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Card>
        </Box>
    );
}
