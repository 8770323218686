import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

export default function ExportExcelIdealTransactions({ disabled, jsonData }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handleExportToExcel = () => {
        let sheetData = [];

        const headerRow = [
            resourceFinal[appLanguage].words.TRANSACTION,
            resourceFinal[appLanguage].words.STATUS,
            `${resourceFinal[appLanguage].words.TRANSACTION}${resourceFinal[appLanguage].words.AMOUNT.toLowerCase()}`,
            resourceFinal[appLanguage].words.STATUS,
            resourceFinal[appLanguage].words.NAME_FIRST,
            resourceFinal[appLanguage].words.NAME_INSERTION,
            resourceFinal[appLanguage].words.NAME_LAST,
            resourceFinal[appLanguage].words.ACTIVITY,
            resourceFinal[appLanguage].words.PERFORMANCE,
            `${resourceFinal[appLanguage].words.PERFORMANCE}${resourceFinal[appLanguage].words.NUMBER.toLowerCase()}`,
            resourceFinal[appLanguage].words.UNSUBSCRIBED_DATE,
            resourceFinal[appLanguage].words.UNSUBSCRIBED_BY,
            resourceFinal[appLanguage].words.DATE_CREATED,
            resourceFinal[appLanguage].words.CREATED_BY,
            resourceFinal[appLanguage].words.DATE_MODIFIED,
            resourceFinal[appLanguage].words.MODIFIED_BY,
        ];

        sheetData.push(headerRow);

        jsonData.forEach(item => {
            sheetData.push(Object.values(item));
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

        const columnWidths = sheetData[0].map((_, colIndex) => ({
            wch: Math.max(...sheetData.map(row => (row[colIndex] ? row[colIndex].toString().length : 10))) + 2
        }));

        worksheet['!cols'] = columnWidths;

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFileXLSX(workbook, `${resourceFinal[appLanguage].words.REPORT_TRANSACTIONS} - ${jsonData[0]?.activityName}.xlsx`);
    };

    return (
        <Button variant="outlined" color="success" disabled={disabled} onClick={() => handleExportToExcel()}>
            {resourceFinal[appLanguage].words.EXPORT} {resourceFinal[appLanguage].words.EXCEL.toLowerCase()}
        </Button>
    );
}
