import { Avatar, IconButton, Modal, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DisplayName, GetDate, GetTime } from '../../../app/AppUtils';
import { fetchAttendanceList, fetchExistingAuthorizations, resetAttendanceStatus, setFailedAuthorizations } from '../../attendance/attendanceSlice';
import { getAllContacts } from '../../contact/contactSlice';
import { getLocations } from '../../management-activity/location/locationManagementSlice';
import { fetchAvailableStudents } from '../../student/studentSlice';
import BackButton from '../../subcomponents/BackButton';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import ListItemCard from '../../subcomponents/ListItemCard';
import PageLayout from '../../subcomponents/PageLayout';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import { fetchPlanningPeriods } from '../planningSlice';
import SubscriptionsDisplay from '../subscriptions/SubscriptionsDisplay';
import UnsubscriptionsDisplay from '../subscriptions/UnsubscriptionsDisplay';
import { fetchSubscriptions, fetchUnsubscriptions, resetSubscriptionStatus } from '../subscriptions/subscriptionSlice';
import AuthorizationAddCard from './AuthorizationAddCard';
import AuthorizationsDisplay from './AuthorizationsDisplay';
import PlanningPerformanceEditCard from './PlanningPerformanceEditCard';
import { deletePerformance, fetchPerformance, resetDefaultActivityValues, resetPlanningPerformanceStatus, selectPerformance, setFailedPerformance } from './planningPerformanceSlice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import ExportExcelAttendance from '../../attendance/ExportExcelAttendance';

export default function PlanningPerformancePage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { number, id } = useParams();

    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { performance, performanceStatus } = useSelector((state) => state.planning.performance);
    const { deleteSubscriptionStatus, putSubscriptionStatus } = useSelector((state) => state.subscription);
    const { attendanceList, attendanceListStatus } = useSelector((state) => state.attendance);

    const [open, setOpen] = useState(false);
    const [openAuthorization, setOpenAuthorization] = useState(false);

    const callbackModal = () => {
        setOpen(false);
        dispatch(resetPlanningPerformanceStatus());
        dispatch(resetDefaultActivityValues());
        dispatch(setFailedPerformance(false));
    };

    const authorizationCallbackModal = () => {
        setOpenAuthorization(false);
        dispatch(resetAttendanceStatus());
        dispatch(setFailedAuthorizations(false));
    };

    useEffect(() => {
        dispatch(fetchAvailableStudents(id));
        dispatch(fetchPerformance(id));
        dispatch(fetchSubscriptions(id));
        dispatch(fetchUnsubscriptions(id));
        dispatch(fetchExistingAuthorizations(id));
        dispatch(fetchAttendanceList(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (deleteSubscriptionStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetSubscriptionStatus());
            dispatch(fetchSubscriptions(id));
            dispatch(fetchUnsubscriptions(id));
            dispatch(fetchAvailableStudents(id));
        } else if (deleteSubscriptionStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetSubscriptionStatus());
        };
    }, [deleteSubscriptionStatus]);

    useEffect(() => {
        if (putSubscriptionStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.UPDATE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetSubscriptionStatus());
            dispatch(fetchSubscriptions(id));
            dispatch(fetchUnsubscriptions(id));
            dispatch(fetchAvailableStudents(id));
        } else if (putSubscriptionStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_UPDATE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetSubscriptionStatus());
        };
    }, [putSubscriptionStatus]);

    useEffect(() => {
        dispatch(getLocations());
        dispatch(getAllContacts());
        dispatch(fetchPlanningPeriods());
    }, [dispatch]);

    const deleteClick = () => {
        dispatch(deletePerformance(id));
        dispatch(selectPerformance(null));
        navigate('/plannen');
    };

    const attendanceClick = () => {
        navigate(`/presentie/uitvoering/${performance.id}`);
    };

    const authorizationClick = () => {
        setOpenAuthorization(true);
    };

    const momentsClick = () => {
        navigate(`/plannen/momenten/${performance.id}`);
    };

    return (
        <PageLayout title={`${performance?.activity?.name ? performance?.activity?.name + " - " : ""}${number}`} content={
            <ContentLoader successCondition={performanceStatus === "success"} errorCondition={performanceStatus === "failed"} content={
                <>
                    <Grid container spacing={1} sx={{ mt: { xs: 0, md: 1 } }}>
                        {/* ROW 1 */}
                        <Grid size={{ xs: 12, md: (appSettings?.appVersion === 2 || siomSettings?.enablePerformanceName) ? 4 : 6 }}>
                            <ListItemCard backgroundStyle={{ height: 1 }}
                                primaryText={performance?.activity?.name}
                                secondaryText={resourceFinal[appLanguage].words.ACTIVITY}
                            />
                        </Grid>
                        {(appSettings?.appVersion === 2 || siomSettings?.enablePerformanceName) &&
                            <Grid size={{ xs: 12, md: 4 }}>
                                <ListItemCard backgroundStyle={{ height: 1 }}
                                    primaryText={performance?.name ? performance?.name : "-"}
                                    secondaryText={resourceFinal[appLanguage].words.NAME}
                                />
                            </Grid>}
                        <Grid size={{ xs: 12, md: (appSettings?.appVersion === 2 || siomSettings?.enablePerformanceName) ? 4 : 6 }}>
                            <ListItemCard backgroundStyle={{ height: 1 }}
                                primaryText={performance?.number}
                                secondaryText={resourceFinal[appLanguage].words.NUMBER}
                            />
                        </Grid>
                        {/* ROW 2 */}
                        <Grid size={{ xs: 12, md: 6 }}>
                            <ListItemCard backgroundStyle={{ height: 1 }}
                                primaryText={performance?.period?.name}
                                secondaryText={resourceFinal[appLanguage].words.PERIOD}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <ListItemCard backgroundStyle={{ height: 1 }}
                                primaryText={performance?.location?.name ? performance?.location?.name : "-"}
                                secondaryText={resourceFinal[appLanguage].words.LOCATION}
                            />
                        </Grid>
                        {/* ROW 3 */}
                        <Grid size={{ xs: 12, md: 3 }}>
                            <ListItemCard
                                primaryText={`${GetDate(performance?.momentStart)} (${GetTime(performance?.momentStart)} - ${GetTime(performance?.momentEnd)})`}
                                secondaryText={`${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.DATE.toLowerCase()}`}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: 3 }}>
                            <ListItemCard
                                primaryText={`${GetDate(performance?.subscribableFrom)} (${GetTime(performance?.subscribableFrom)})`}
                                secondaryText={resourceFinal[appLanguage].words.SUBSCRIBABLE_FROM}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: 3 }}>
                            <ListItemCard
                                primaryText={`${GetDate(performance?.subscribableUntil)} (${GetTime(performance?.subscribableUntil)})`}
                                secondaryText={resourceFinal[appLanguage].words.SUBSCRIBABLE_UNTIL}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: 3 }}>
                            <ListItemCard
                                primaryText={performance?.alwaysAvailable ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                                secondaryText={resourceFinal[appLanguage].words.ALWAYS_AVAILABLE}
                            />
                        </Grid>
                        {/* ROW 4 */}
                        <Grid size={{ xs: 12, md: (appSettings?.appVersion === 1 && !siomSettings?.flagProgress) ? 3 : 4 }}>
                            <ListItemCard
                                action={() => momentsClick()}
                                primaryText={performance?.moments}
                                secondaryText={performance?.moments === 1 ? resourceFinal[appLanguage].words.MOMENT : resourceFinal[appLanguage].words.MOMENTS}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: (appSettings?.appVersion === 1 && !siomSettings?.flagProgress) ? 3 : 4 }}>
                            <ListItemCard
                                primaryText={`${performance?.capacityCurrent}/${performance?.capacityLimit}`}
                                secondaryText={resourceFinal[appLanguage].words.CAPACITY}
                            />
                        </Grid>
                        {!siomSettings?.flagProgress &&
                            <Grid size={{ xs: 12, md: appSettings?.appVersion === 1 ? 3 : 4 }}>
                                <ListItemCard
                                    primaryText={performance?.points ? performance?.points : "-"}
                                    secondaryText={appSettings?.appVersion === 1 ? resourceFinal[appLanguage].words.POINTS : resourceFinal[appLanguage].words.HOURS}
                                />
                            </Grid>}
                        {appSettings?.appVersion === 1 && <Grid size={{ xs: 12, md: !siomSettings?.flagProgress ? 3 : 4 }}>
                            <ListItemCard
                                primaryText={performance?.cost ? Intl.NumberFormat('nl-NL', { style: "currency", currency: "EUR", }).format(performance?.cost) : "-"}
                                secondaryText={resourceFinal[appLanguage].words.COST}
                            />
                        </Grid>}
                        {/* ROW 5 */}
                        <Grid size={{ xs: 12, md: 4 }}>
                            <ListItemCard
                                primaryText={performance?.contact ? DisplayName(performance?.contact?.firstname, performance?.contact?.insertion, performance?.contact?.lastname) : "-"}
                                secondaryText={resourceFinal[appLanguage].words.CONTACT}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }}>
                            <ListItemCard
                                primaryText={performance?.primaryAttendant ? DisplayName(performance?.primaryAttendant?.firstname, performance?.primaryAttendant?.insertion, performance?.primaryAttendant?.lastname) : "-"}
                                secondaryText={`${resourceFinal[appLanguage].words.PRIMARY} ${resourceFinal[appLanguage].words.ATTENDANT.toLowerCase()}`}
                            />
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }}>
                            <ListItemCard
                                primaryText={performance?.secondaryAttendant ? DisplayName(performance?.secondaryAttendant?.firstname, performance?.secondaryAttendant?.insertion, performance?.secondaryAttendant?.lastname) : "-"}
                                secondaryText={`${resourceFinal[appLanguage].words.SECONDARY} ${resourceFinal[appLanguage].words.ATTENDANT.toLowerCase()}`}
                            />
                        </Grid>
                        {/* ROW 6 */}
                        <Grid size={{ xs: 12 }}>
                            <ListItemCard
                                primaryText={performance?.remark ? performance?.remark : "-"}
                                secondaryText={resourceFinal[appLanguage].words.PERFORMANCE_REMARK}
                            />
                        </Grid>
                        {/* END */}
                        <Grid size={{ xs: 12 }}>
                            <SubscriptionsDisplay performanceId={id} />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <UnsubscriptionsDisplay />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <AuthorizationsDisplay performanceId={id} />
                        </Grid>
                    </Grid>
                    <Modal open={open} onClose={callbackModal}>
                        <>
                            <PlanningPerformanceEditCard handleClose={callbackModal} id={id} />
                        </>
                    </Modal>
                    <Modal open={openAuthorization} onClose={authorizationCallbackModal}>
                        <>
                            <AuthorizationAddCard handleClose={authorizationCallbackModal} performanceId={id} />
                        </>
                    </Modal>
                </>
            } />
        } contentRight={
            <Grid container spacing={0.5} direction="column" justifyContent="flex-end" alignItems="flex-end" sx={{ width: 1 }}>
                {performanceStatus === "success" &&
                    <>
                        <Grid>
                            <Tooltip title={resourceFinal[appLanguage].words.AUTHORIZE} placement="left" arrow>
                                <IconButton sx={{ alignSelf: 'flex-end' }} onClick={authorizationClick}>
                                    <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                        <PersonAddAlt1Icon sx={{ color: 'white.main', fontSize: 40 }} />
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid>
                            <ExportExcelAttendance disabled={attendanceListStatus !== "success"} jsonData={attendanceList} />
                        </Grid>
                        <Grid>
                            <Tooltip title={resourceFinal[appLanguage].words.ATTENDANCE} placement="left" arrow>
                                <IconButton sx={{ alignSelf: 'flex-end' }} onClick={attendanceClick}>
                                    <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                        <HistoryEduIcon sx={{ color: 'white.main', fontSize: 40 }} />
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid>
                            <Tooltip title={resourceFinal[appLanguage].words.EDIT} placement="left" arrow>
                                <IconButton sx={{ alignSelf: 'flex-end' }} onClick={() => setOpen(true)}>
                                    <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                        <EditIcon sx={{ color: 'white.main', fontSize: 40 }} />
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid>
                            <Tooltip title={resourceFinal[appLanguage].words.DELETE} placement="left" arrow>
                                <Avatar sx={{ alignSelf: 'flex-end', bgcolor: 'primary.main', p: 0.5, m: 1 }}>
                                    <ConfirmationModal iconButton={true} icon={<DeleteIcon sx={{ color: 'white.main', fontSize: 40 }} />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${performance?.name ? performance?.name : performance?.number} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={deleteClick} />
                                </Avatar>
                            </Tooltip>
                        </Grid>
                    </>
                }
                <Grid sx={{ borderTop: 1, mt: 0.5, pt: 0.5, borderColor: "lightgrey" }}>
                    <BackButton desktop />
                </Grid>
            </Grid>
        } bottomBar />
    );
}
