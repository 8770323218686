import { Avatar, Box, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ListItemBackground, TableHeader } from '../../../app/AppStyles';
import { GetDate, GetTime } from '../../../app/AppUtils';
import { fetchMomentAttendance } from '../../attendance/attendanceSlice';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import BackButton from '../../subcomponents/BackButton';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import PageLayout from '../../subcomponents/PageLayout';
import PlanningMomentsEditCard from './PlanningMomentsEditCard';
import { deletePlanningMoment, fetchPlanningMoments, resetPlanningMomentStatus } from './planningMomentsSlice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AddIcon from '@mui/icons-material/Add';

export default function PlanningMomentsPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { planningMoments, planningMomentsStatus, deletePlanningMomentStatus } = useSelector((state) => state.planning.moments);

    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [selectedMoment, setSelectedMoment] = useState();
    const [newMomentInfo, setNewMomentInfo] = useState();

    useEffect(() => {
        dispatch(fetchPlanningMoments(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (planningMomentsStatus === "success") {
            setNewMomentInfo({
                performanceId: planningMoments[0].performanceId,
                locationId: planningMoments[0].locationId
            });
        }
    }, [planningMomentsStatus]);

    useEffect(() => {
        if (deletePlanningMomentStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(fetchPlanningMoments(id));
            dispatch(resetPlanningMomentStatus());
        } else if (deletePlanningMomentStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetPlanningMomentStatus());
        };
    }, [deletePlanningMomentStatus]);

    const handleCallbackModal = () => {
        setOpenNew(false);
        setOpenEdit(false);
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - planningMoments?.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const handleAttendanceClick = (momentId) => {
            dispatch(fetchMomentAttendance(momentId));
            navigate(`/presentie/moment/${momentId}`);
        };

        const handleEditClick = (moment) => {
            setSelectedMoment(moment);
            setOpenEdit(true);
        };

        const handleDeleteClick = (momentId) => {
            dispatch(deletePlanningMoment(momentId))
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? planningMoments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : planningMoments).map((item, index) =>
                        <TableRow key={index} sx={{ cursor: 'pointer', '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="center">{GetDate(item.startTime)} ({GetTime(item.startTime)})</TableCell>
                            <TableCell align="center">{GetDate(item.endTime)} ({GetTime(item.endTime)})</TableCell>
                            <TableCell align="left">{item.activityName}</TableCell>
                            <TableCell align="center">{item.performanceNumber}</TableCell>
                            <TableCell align="center">{item.performanceName ? item.performanceName : "-"}</TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => handleAttendanceClick(item.id)}><HistoryEduIcon color="secondary" /></IconButton></TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => handleEditClick(item)}><EditIcon color="secondary" /></IconButton></TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${resourceFinal[appLanguage].messages.THE_MOMENT} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => handleDeleteClick(item.id)} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? planningMoments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : planningMoments).length === 0 && <TableRow><TableCell colSpan={6}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={6} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: planningMoments?.length < 100 ? resourceFinal[appLanguage].words.ALL : '100',
                                    value: planningMoments?.length < 100 ? -1 : 100
                                }
                            ]}
                            count={planningMoments?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };


    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.MOMENTS}`} content={
            <ContentLoader successCondition={planningMomentsStatus === "success"} errorCondition={planningMomentsStatus === "failed"} content={
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', ...ListItemBackground }}>
                        <TableContainer>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.START}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.END}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                        <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }} padding="checkbox">{resourceFinal[appLanguage].words.ATTENDANCE}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }} padding="checkbox">{resourceFinal[appLanguage].words.EDIT}</TableCell>
                                        <TableCell align="center" sx={{ ...TableHeader }} padding="checkbox">{resourceFinal[appLanguage].words.DELETE}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableFragment />
                            </Table>
                        </TableContainer>
                    </Box>
                    <Modal open={openNew} onClose={handleCallbackModal}>
                        <>
                            <PlanningMomentsEditCard addNew handleClose={handleCallbackModal} performanceId={id} selectedMoment={selectedMoment} newMomentInfo={newMomentInfo} />
                        </>
                    </Modal>
                    <Modal open={openEdit} onClose={handleCallbackModal}>
                        <>
                            <PlanningMomentsEditCard handleClose={handleCallbackModal} performanceId={id} selectedMoment={selectedMoment} />
                        </>
                    </Modal>
                </>
            } />
        } contentRight={
            <Grid container spacing={0.5} direction="column" justifyContent="flex-end" alignItems="flex-end" sx={{ width: 1 }}>
                <Grid>
                    <Tooltip title={resourceFinal[appLanguage].words.ADD} placement="left" arrow>
                        <IconButton sx={{ alignSelf: 'flex-end' }} onClick={() => setOpenNew(true)}>
                            <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                                <AddIcon sx={{ color: 'white.main', fontSize: 40 }} />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid sx={{ borderTop: 1, mt: 0.5, pt: 0.5, borderColor: "lightgrey" }}>
                    <BackButton desktop />
                </Grid>
            </Grid>
        } />
    );
}
