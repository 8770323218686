import Grid from '@mui/material/Grid2';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPostReservation } from '../mollie/mollieSlice';
import { fetchUserProgress } from "../progress/progressSlice";
import { resetRegisterStatusses } from '../register/registerSlice';
import { fetchSchedule } from '../schedule/scheduleSlice';

// Components
import FlagTile from '../flags/FlagsTile';
import NewsTile from '../news/NewsTile';
import ProgressTile from '../progress/ProgressTile';
import ScheduleTile from '../schedule/ScheduleTile';
import InfoTile from "./InfoTile";
import TileButtons from './TileButtons';
import ProgramStatusTile from './ProgramStatusTile';

export default function StudentHome() {
    const dispatch = useDispatch();
    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { tiles } = useSelector((state) => state.settings.resource.navigation);
    const { activeDepartment, activeThemeSettings } = useSelector((state) => state.settings.theme);
    const { subscriptionStatus, deleteStatus } = useSelector((state) => state.register);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { postReservationStatus } = useSelector((state) => state.mollie);

    useEffect(() => { // Reload schedule if pay later has been clicked
        if (postReservationStatus === "success") {
            dispatch(resetPostReservation());
            dispatch(fetchSchedule());
        };
    }, [dispatch, postReservationStatus]);

    useEffect(() => {
        if (!aspNetUser || (siomSettings.useMultipleDepartments && !activeDepartment)) return;

        const departmentId = siomSettings.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null;

        dispatch(fetchSchedule());
        dispatch(fetchUserProgress({ departmentId, fullProgress: false }));

        if (subscriptionStatus === "success" || deleteStatus === "success") {
            dispatch(resetRegisterStatusses());
        }
    }, [dispatch, subscriptionStatus, deleteStatus, aspNetUser, siomSettings, activeDepartment]);

    return (
        <Grid container direction="column" wrap="nowrap" spacing={{ xs: 0.5, md: 1 }} sx={{ flex: '1 1 auto' }}>
            {tiles?.includes("Voortgang") &&
                <Grid container spacing={{ xs: 0.5, md: 1 }}>
                    <Grid size={{ xs: 12 }} sx={{ display: { xs: 'none', md: 'flex' } }}><ProgramStatusTile /></Grid>
                    <Grid size={{ xs: 12, md: activeThemeSettings?.flagsEnabled ? 4 : 6 }}><InfoTile /></Grid>
                    <Grid size={{ xs: 12 }} sx={{ display: { xs: 'flex', md: 'none' } }}><ProgramStatusTile /></Grid>
                    <Grid size={{ xs: 8, md: activeThemeSettings?.flagsEnabled ? 4 : 6 }}><ProgressTile /></Grid>
                    {activeThemeSettings?.flagsEnabled && <Grid size={{ xs: 4, md: 4 }}><FlagTile /></Grid>}
                </Grid>}
            {tiles?.includes("Agenda") && <Grid size={{ xs: "grow" }} sx={{ display: 'flex', flex: '1 1 auto' }}><ScheduleTile /></Grid>}
            {tiles?.includes("Nieuws") && <Grid size={{ xs: 2 }} sx={{ display: { xs: 'none', md: 'flex' } }}><NewsTile /></Grid>}
            <Grid sx={{ display: { xs: 'flex', md: 'none' } }}><TileButtons /></Grid>
        </Grid>
    );
}
