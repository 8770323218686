import { alpha, Box, Button, CardMedia, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import LocationEditCard from './LocationEditCard';
import { deleteLocation, getLocations, resetLocationStatus, selectLocation, setFailedLocation } from './locationManagementSlice';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { SearchKeyFilter } from '../../../app/AppUtils';

export default function LocationTab() {
    const dispatch = useDispatch();
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [filter, setFilter] = useState("");
    const textRef = useRef(null);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { locationsStatus, deleteLocationStatus } = useSelector((state) => state.management.location);

    useEffect(() => {
        if (deleteLocationStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetLocationStatus());
            dispatch(getLocations);
        } else if (deleteLocationStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetLocationStatus());
        };
    }, [deleteLocationStatus]);

    useEffect(() => {
        if (locationsStatus !== "success")
            dispatch(getLocations());
    }, [dispatch, locationsStatus]);

    const callbackModalEdit = () => {
        setOpenEdit(false);
        dispatch(setFailedLocation(false));
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(setFailedLocation(false));
    };

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["naam", "beschrijving"], filter);
    }

    const newClick = () => {
        dispatch(selectLocation(null));
        setOpenNew(!openNew);
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);
        const { locations } = useSelector((state) => state.management.location);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - locations?.filter(handleFilterResult).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const editClick = (location) => {
            dispatch(selectLocation(location));
            setOpenEdit(!openEdit);
        };

        const deleteClick = (locationId) => {
            dispatch(deleteLocation(locationId));
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? locations.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : locations.filter(handleFilterResult)).map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.naam ? item.naam : "-"}</TableCell>
                            <Tooltip title={item.beschrijving}>
                                <TableCell align="left" sx={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "200px",
                                    width: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{item.beschrijving ? item.beschrijving : "-"}</TableCell>
                            </Tooltip>
                            <TableCell align="center">{item.straatnaam ? item.straatnaam : "-"}</TableCell>
                            <TableCell align="center">{item.huisnummer ? item.huisnummer : "-"}</TableCell>
                            <TableCell align="center">{item.plaatsnaam ? item.plaatsnaam : "-"}</TableCell>
                            <TableCell align="center">{item.land ? item.land : "-"}</TableCell>
                            <TableCell align="center">{item.postcode ? item.postcode : "-"}</TableCell>
                            <TableCell align="center">{item.telefoonnummer ? item.telefoonnummer : "-"}</TableCell>
                            <TableCell align="center">{item.email ? item.email : "-"}</TableCell>
                            <TableCell align="center">
                                {item.imageUrl ? <CardMedia component="img" sx={{ height: 50, width: 50, mx: 'auto' }} image={item.imageUrl} alt="location" /> : "-"}
                            </TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => editClick(item)}><EditIcon color="secondary" /></IconButton></TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${item.naam} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => deleteClick(item.id)} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? locations.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : locations.filter(handleFilterResult)).length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={12} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 25,
                                {
                                    label: locations?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : '100',
                                    value: locations?.filter(handleFilterResult).length < 100 ? -1 : 100
                                }
                            ]}
                            count={locations?.filter(handleFilterResult).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <>
            <ContentLoader successCondition={locationsStatus === "success"} errorCondition={locationsStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                        <SearchField func={handleSearchChange} textRef={textRef} />
                        <Button variant="outlined" onClick={newClick} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                    </Box>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION_STREET}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION_HOUSENUMBER}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION_PLACE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION_COUNTRY}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION_ZIPCODE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PHONENUMBER}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.EMAIL}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.IMAGE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box>
            } />

            <Modal open={openEdit} onClose={callbackModalEdit}>
                <>
                    <LocationEditCard handleClose={callbackModalEdit} />
                </>
            </Modal>

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <LocationEditCard handleClose={callbackModalNew} addNew />
                </>
            </Modal>
        </>
    );
}
