import Grid from '@mui/material/Grid2';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchCount } from '../news/newsSlice';
import TileButton from './TileButton';

export default function TileButtons() {
    const dispatch = useDispatch();
    const { aspNetUser } = useSelector((state) => state.settings.app);
    const { tileButtons } = useSelector((state) => state.settings.resource.navigation);

    useEffect(() => {
        if (aspNetUser !== null) {
            dispatch(fetchCount());
        };
    }, [dispatch, aspNetUser]);

    return (
        <Grid container spacing={{ xs: 0.5, md: 1 }} columns={{ xs: 6, md: 12 }} sx={{ width: 1 }} justifyContent="center">
            {tileButtons.map((item) =>
                <Grid size={{ xs: 2, md: "grow" }} key={item}>
                    <TileButton key={item} text={item}></TileButton>
                </Grid>
            )}
        </Grid>
    );
}
