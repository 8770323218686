import { Box, Checkbox, FormControlLabel, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { ListItemBackground, TableHeader } from '../../../app/AppStyles';
import ContentLoader from '../../subcomponents/ContentLoader';
import FilterButton from '../../subcomponents/FilterButton';
import FilterSidebar from '../../subcomponents/FilterSidebar';
import PageLayout from '../../subcomponents/PageLayout';
import { fetchSearchSchoolclasses } from './searchSchoolclassSlice';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SearchKeyFilter } from '../../../app/AppUtils';

export default function SearchClassPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { searchSchoolclasses, searchSchoolclassesStatus } = useSelector((state) => state.search.schoolclass);

    const [filter, setFilter] = useState("");
    const [isLicensed, setIsLicensed] = useState(true);

    useEffect(() => {
        dispatch(fetchSearchSchoolclasses(isLicensed));
    }, [dispatch, isLicensed]);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["name", "employeeNumber"], filter);
    }

    const NavigationFragment = () => {
        return (
            <ToggleButtonGroup
                color="primary"
                fullWidth
                exclusive
                value="CLASSES"
                onChange={() => navigate('/zoeken/student')}
            >
                <ToggleButton value="STUDENTS">{resourceFinal[appLanguage].words.STUDENTS}</ToggleButton>
                <ToggleButton value="CLASSES">{resourceFinal[appLanguage].words.CLASSES}</ToggleButton>
            </ToggleButtonGroup>
        );
    };

    const FilterFragment = () => {
        return (
            <FormControlLabel sx={{ alignSelf: 'center' }} control={<Checkbox checked={isLicensed} onChange={() => setIsLicensed(!isLicensed)} />} label={`${resourceFinal[appLanguage].words.LICENSED}`} />
        );
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchSchoolclasses?.filter(handleFilterResult).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? searchSchoolclasses.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : searchSchoolclasses.filter(handleFilterResult)).map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="center">
                                <Link to={`/zoeken/klas/${item.id}`} style={{ color: 'black' }}>
                                    {item.name}
                                </Link>
                            </TableCell>
                            <TableCell align="center">{item.employeeNumber ? item.employeeNumber : "-"}</TableCell>
                            <TableCell align="center">{item.studentsCount}</TableCell>
                            <TableCell align="center">{item.isLicensed ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}</TableCell>
                            <TableCell align="center">
                                <IconButton onClick={() => navigate(`/zoeken/klas/${item.id}`)}>
                                    <ArrowForwardIosIcon color="secondary" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )}

                    {(rowsPerPage > 0 ? searchSchoolclasses.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : searchSchoolclasses.filter(handleFilterResult)).length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 63 * emptyRows }}>
                            <TableCell colSpan={12} />
                        </TableRow>)}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: searchSchoolclasses?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : '100',
                                    value: searchSchoolclasses?.filter(handleFilterResult).length < 100 ? -1 : 100
                                }
                            ]}
                            count={searchSchoolclasses?.filter(handleFilterResult).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <PageLayout title={`${resourceFinal[appLanguage].words.CLASS}`} titleLower={`${resourceFinal[appLanguage].words.SEARCH}`} content={
            <ContentLoader successCondition={searchSchoolclassesStatus === "success"} errorCondition={searchSchoolclassesStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', ...ListItemBackground }}>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ROLE_EMPLOYEE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.STUDENTS}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LICENSED}</TableCell>
                                    <TableCell align="center" padding="checkbox"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box>
            } />
        } contentLeft={
            <FilterSidebar topContent={<NavigationFragment />} searchFunc={handleSearchChange} filters={<FilterFragment />} />
        } bottomBar searchFunc={handleSearchChange} contentBottom={
            <FilterButton filters={<FilterFragment />} />
        } />
    );
}
