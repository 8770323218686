import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';

export default function ExportExcelUnsubscriptions({ disabled, jsonData }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const handleExportToExcel = () => {
        let sheetData = [];

        const headerRow = [
            resourceFinal[appLanguage].words.ACTIVITY,
            resourceFinal[appLanguage].words.PERFORMANCE,
            `${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`,
            resourceFinal[appLanguage].words.EMAIL,
            resourceFinal[appLanguage].words.PHONENUMBER,
            resourceFinal[appLanguage].words.NUMBER,
            resourceFinal[appLanguage].words.NAME_FIRST,
            resourceFinal[appLanguage].words.NAME_INSERTION,
            resourceFinal[appLanguage].words.NAME_LAST,
            resourceFinal[appLanguage].words.UNSUBSCRIBED_DATE,
            resourceFinal[appLanguage].words.UNSUBSCRIBED_BY,
        ];

        sheetData.push(headerRow);

        jsonData.forEach(item => {
            sheetData.push(Object.values(item));
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

        const columnWidths = sheetData[0].map((_, colIndex) => ({
            wch: Math.max(...sheetData.map(row => (row[colIndex] ? row[colIndex].toString().length : 10))) + 2
        }));

        worksheet['!cols'] = columnWidths;

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFileXLSX(workbook, `${resourceFinal[appLanguage].words.REPORT_UNSUBSCRIPTIONS} - ${jsonData[0]?.activityName}.xlsx`);
    };

    return (
        <Button variant="outlined" color="success" disabled={disabled} onClick={() => handleExportToExcel()}>
            {resourceFinal[appLanguage].words.EXPORT} {resourceFinal[appLanguage].words.EXCEL.toLowerCase()}
        </Button>
    );
}
