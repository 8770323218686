import { alpha, Box, Button, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import { AddZero, GetDate, GetTime } from '../../../app/AppUtils';
import ContentLoader from '../../subcomponents/ContentLoader';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import ExportExcelMoments from './ExportExcelMoments';
import { fetchMomentReport, resetReportMoments } from './reportMomentsSlice';

export default function ReportMomentsTab() {
    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searching, setSearching] = useState(false);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { momentReport, momentReportStatus } = useSelector((state) => state.reports.moments);

    const handleSearchClick = () => {
        setSearching(true);
        dispatch(fetchMomentReport({ startDate: startDate, endDate: endDate }));
    };

    useEffect(() => {
        dispatch(resetReportMoments())
    }, []);

    useEffect(() => {
        if (momentReportStatus === "success" || momentReportStatus === "failed")
            setSearching(false);
    }, [momentReportStatus]);

    const generatePDF = () => {
        const doc = new JsPDF('landscape', 'pt', 'a4');
        doc.text(`${resourceFinal[appLanguage].words.MOMENTS} ${startDate} - ${endDate}`, 20, 10);
        doc.autoTable({
            body: momentReport,
            columns: [
                { header: `${resourceFinal[appLanguage].words.ACTIVITY}${resourceFinal[appLanguage].words.NAME.toLowerCase()}`, dataKey: 'activityName' },
                { header: `${resourceFinal[appLanguage].words.PERFORMANCE}${resourceFinal[appLanguage].words.NAME.toLowerCase()}`, dataKey: 'performanceName' },
                { header: `${resourceFinal[appLanguage].words.PERFORMANCE}${resourceFinal[appLanguage].words.NUMBER.toLowerCase()}`, dataKey: 'performanceNumber' },
                { header: `${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`, dataKey: 'startTime' },
                { header: `${resourceFinal[appLanguage].words.END}${resourceFinal[appLanguage].words.TIME.toLowerCase()}`, dataKey: 'endTime' },
                { header: `${resourceFinal[appLanguage].words.LOCATION}`, dataKey: 'locationName' },
                { header: `${resourceFinal[appLanguage].words.SUBSCRIPTIONS}`, dataKey: 'capacityCurrent' },
                { header: `${resourceFinal[appLanguage].words.CAPACITY}`, dataKey: 'capacityLimit' },
            ],
        });
        doc.save(`${resourceFinal[appLanguage].words.MOMENTS}_${startDate}_${endDate}.pdf`);
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - momentReport?.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? momentReport?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : momentReport)?.map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.activityName}</TableCell>
                            <TableCell align="left">{item.performanceName}</TableCell>
                            <TableCell align="center">{item.performanceNumber}</TableCell>
                            <TableCell align="center">{GetDate(item.startTime)} ({GetTime(item.startTime)})</TableCell>
                            <TableCell align="center">{GetDate(item.endTime)} ({GetTime(item.endTime)})</TableCell>
                            <TableCell align="center">{item.locationName}</TableCell>
                            <TableCell align="center">{item.capacityCurrent}/{item.capacityLimit}</TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? momentReport?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : momentReport)?.length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: momentReport?.length < 100 ? resourceFinal[appLanguage].words.ALL : '100',
                                    value: momentReport?.length < 100 ? -1 : 100
                                }
                            ]}
                            count={momentReport ? momentReport?.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                <Box sx={{ display: 'flex', bgcolor: theme => alpha(theme.palette.white.main, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                    <Box sx={{ display: 'flex', mr: 1 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                            <DatePicker sx={{ mr: 1 }}
                                label={`${resourceFinal[appLanguage].words.START}${resourceFinal[appLanguage].words.DATE.toLowerCase()}`}
                                onChange={(e) => setStartDate(`${e.$y}-${AddZero(e.$M + 1)}-${e.$D}`)} />
                            <DatePicker
                                label={`${resourceFinal[appLanguage].words.END}${resourceFinal[appLanguage].words.DATE.toLowerCase()}`}
                                onChange={(e) => setEndDate(`${e.$y}-${AddZero(e.$M + 1)}-${e.$D}`)} />
                        </LocalizationProvider>
                        <Button variant="outlined" color="secondary" sx={{ ml: 1 }} disabled={!startDate || !endDate} onClick={handleSearchClick}>{resourceFinal[appLanguage].words.REPORT_GENERATE}</Button>
                    </Box>
                    <ExportExcelMoments disabled={searching || !momentReport} jsonData={momentReport} fileName={`${resourceFinal[appLanguage].words.REPORT_MOMENTS} - ${startDate} - ${endDate}`} />
                    <Button sx={{ ml: 1 }} variant="outlined" color="primary" onClick={generatePDF} disabled={searching || !momentReport}>{resourceFinal[appLanguage].words.EXPORT} {resourceFinal[appLanguage].words.PDF}</Button>
                </Box>
                <ContentLoader successCondition={searching === false} errorCondition={momentReportStatus === "failed"} content={
                    <TableContainer>
                        <Table size="small" id="#report">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NAME.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PERFORMANCE}{resourceFinal[appLanguage].words.NUMBER.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.START}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.END}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.LOCATION}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.CAPACITY}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                } />
            </Box>
        </>
    );
}
