import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../../api-authorization/AuthorizeService";

export const fetchPlanningMoments = createAsyncThunk(
    '/api/planningMoments/id',
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningMoments/${performanceId}`, {
            headers: new Headers({
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'GET',
            redirect: 'follow'
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const postPlanningMoment = createAsyncThunk(
    '/api/planningMoments/post',
    async (newPlanningMoment, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningMoments`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'POST',
            redirect: 'follow',
            body: JSON.stringify(newPlanningMoment)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const putPlanningMoment = createAsyncThunk(
    '/api/planningMoments/put',
    async (updatedPlanningMoment, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningMoments`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'PUT',
            redirect: 'follow',
            body: JSON.stringify(updatedPlanningMoment)
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const deletePlanningMoment = createAsyncThunk(
    '/api/planningMoments/delete',
    async (momentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/planningMoments/${momentId}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);


export const planningMomentsSlice = createSlice({
    name: 'planningMoments',
    initialState: {
        planningMoments: null,
        planningMomentsStatus: null,
        postPlanningMomentStatus: null,
        putPlanningMomentStatus: null,
        deletePlanningMomentStatus: null,
    },
    reducers: {
        resetPlanningMomentStatus: (state) => {
            state.putPlanningMomentStatus = null;
            state.postPlanningMomentStatus = null;
            state.deletePlanningMomentStatus = null;
        }
    },
    extraReducers: (builder) => {
        // Get planning moments
        builder
            .addCase(fetchPlanningMoments.pending, (state) => {
                state.planningMomentsStatus = 'loading';
            })
            .addCase(fetchPlanningMoments.fulfilled, (state, action) => {
                state.planningMoments = action.payload;
                state.planningMomentsStatus = 'success';
            })
            .addCase(fetchPlanningMoments.rejected, (state) => {
                state.planningMomentsStatus = 'failed';
            });

        // Add planning moment
        builder
            .addCase(postPlanningMoment.pending, (state) => {
                state.postPlanningMomentStatus = 'loading';
            })
            .addCase(postPlanningMoment.fulfilled, (state) => {
                state.postPlanningMomentStatus = 'success';
            })
            .addCase(postPlanningMoment.rejected, (state) => {
                state.postPlanningMomentStatus = 'failed';
            });

        // Update planning moment
        builder
            .addCase(putPlanningMoment.pending, (state) => {
                state.putPlanningMomentStatus = 'loading';
            })
            .addCase(putPlanningMoment.fulfilled, (state) => {
                state.putPlanningMomentStatus = 'success';
            })
            .addCase(putPlanningMoment.rejected, (state) => {
                state.putPlanningMomentStatus = 'failed';
            });

        // Delete planning moment
        builder
            .addCase(deletePlanningMoment.pending, (state) => {
                state.deletePlanningMomentStatus = 'loading';
            })
            .addCase(deletePlanningMoment.fulfilled, (state) => {
                state.deletePlanningMomentStatus = 'success';
            })
            .addCase(deletePlanningMoment.rejected, (state) => {
                state.deletePlanningMomentStatus = 'failed';
            });
    }
});

export const { resetPlanningMomentStatus } = planningMomentsSlice.actions;

export default planningMomentsSlice.reducer;