import { Avatar, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { GetDate } from '../../app/AppUtils';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function ExportExcelAttendance({ disabled, jsonData }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [alteredData, setAlteredData] = useState([]);

    useEffect(() => {
        if (jsonData) {
            let students = jsonData?.attendanceListStudents.map(({ studentId, ...rest }) => rest);

            setAlteredData(students);
        }
    }, [jsonData]);

    const handleExportToExcel = () => {
        let sheetData = [];

        const headerRow = [
            `${resourceFinal[appLanguage].words.STUDENT}${resourceFinal[appLanguage].words.NAME.toLowerCase()}`,
            `${resourceFinal[appLanguage].words.STUDENT}${resourceFinal[appLanguage].words.NUMBER.toLowerCase()}`,
            resourceFinal[appLanguage].words.CLASS
        ];

        const momentDates = [...alteredData[0].attendanceMoments.map(m => GetDate(m.momentStart))];

        headerRow.push(...momentDates);

        sheetData.push([`${jsonData.activityName} - ${jsonData.performanceNumber}`]);
        sheetData.push(headerRow);

        alteredData.forEach(item => {
            let row = [
                item.studentFullName,
                item.studentNumber,
                item.studentSchoolclass
            ];

            item.attendanceMoments.forEach(a => {
                row.push(a.attendance !== null ? (a.attendance ? resourceFinal[appLanguage].words.PRESENT : resourceFinal[appLanguage].words.PRESENT_NOT) : "");
            });

            sheetData.push(row);
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);

        const columnWidths = sheetData[1].map((_, colIndex) => ({
            wch: Math.max(...sheetData.map(row => (row[colIndex] ? row[colIndex].toString().length : 10))) + 2
        }));

        worksheet['!cols'] = columnWidths;

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFileXLSX(workbook, `${resourceFinal[appLanguage].words.ATTENDANCE} - ${jsonData.activityName} (${jsonData.performanceNumber}).xlsx`);
    };

    return (
        <>
            <Tooltip title={`${resourceFinal[appLanguage].words.ATTENDANCE} ${resourceFinal[appLanguage].words.EXPORT}`} placement="left" arrow>
                <IconButton sx={{ alignSelf: 'flex-end' }} disabled={disabled} onClick={() => handleExportToExcel()}>
                    <Avatar sx={{ bgcolor: 'primary.main', p: 0.5 }}>
                        <FileDownloadIcon sx={{ color: 'white.main', fontSize: 40 }} />
                    </Avatar>
                </IconButton>
            </Tooltip>
        </>
    );
}
