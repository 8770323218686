import { Box, Button, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, alpha } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainBorderRadius, TableHeader } from '../../../app/AppStyles';
import { SearchKeyFilter, GetDate, GetTime } from '../../../app/AppUtils';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import PeriodEditCard from './PeriodEditCard';
import { deletePeriod, getPeriods, resetPeriodStatus, selectPeriod, setFailedPeriod } from './periodManagementSlice';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function PeriodsTab() {
    const dispatch = useDispatch();
    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [filter, setFilter] = useState("");
    const textRef = useRef(null);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { periods, periodsStatus, deletePeriodStatus } = useSelector((state) => state.management.period);

    useEffect(() => {
        dispatch(getPeriods());
    }, [dispatch]);

    useEffect(() => {
        if (deletePeriodStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetPeriodStatus());
            dispatch(getPeriods());
        } else if (deletePeriodStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetPeriodStatus());
        };
    }, [dispatch, resourceFinal, appLanguage, deletePeriodStatus]);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["name"], filter);
    }

    const newClick = () => {
        dispatch(selectPeriod(null));
        dispatch(setFailedPeriod(false));
        setOpenNew(true);
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        setFilter("");
        dispatch(resetPeriodStatus());
        dispatch(selectPeriod(null));
        dispatch(setFailedPeriod(false));
    };

    const callbackModalEdit = () => {
        setOpenEdit(false);
        setFilter("");
        dispatch(resetPeriodStatus());
        dispatch(selectPeriod(null));
        dispatch(setFailedPeriod(false));
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - periods?.filter(handleFilterResult).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const editClick = (period) => {
            dispatch(selectPeriod(period));
            setOpenEdit(true);
        };

        const deleteClick = (periodId) => {
            dispatch(deletePeriod(periodId));
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? periods.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : periods.filter(handleFilterResult)).map(item =>
                        <TableRow key={item.periodId} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.name ? item.name : "-"}</TableCell>
                            <TableCell align="left">{GetDate(item.startTime)} {(GetTime(item.startTime))}</TableCell>
                            <TableCell align="left">{GetDate(item.endTime)} {(GetTime(item.endTime))}</TableCell>
                            <TableCell align="center" padding="checkbox">
                                <IconButton onClick={() => editClick(item)}><EditIcon color="secondary" /></IconButton>
                            </TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${item.name} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => deleteClick(item.periodId)} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? periods.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : periods.filter(handleFilterResult)).length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: periods?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : '100',
                                    value: periods?.filter(handleFilterResult).length < 100 ? -1 : 100
                                }
                            ]}
                            count={periods?.filter(handleFilterResult).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <>
            <ContentLoader successCondition={periodsStatus === "success"} errorCondition={periodsStatus === "failed"} content={
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                        <SearchField func={handleSearchChange} textRef={textRef} />
                        <Button variant="outlined" onClick={newClick} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                    </Box>
                    <TableContainer>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.START}</TableCell>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.END}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableFragment />
                        </Table>
                    </TableContainer>
                </Box>
            } />

            <Modal open={openEdit} onClose={callbackModalEdit}>
                <>
                    <PeriodEditCard handleClose={callbackModalEdit} />
                </>
            </Modal>

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <PeriodEditCard handleClose={callbackModalNew} addNew />
                </>
            </Modal>
        </>
    );
}
