import { Box, Card, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CardHeader, CardMedium } from '../../../../app/AppStyles';
import FlagDisplay from '../../../flags/FlagDisplay';
import ProgressActivities from '../../../progress/ProgressActivities';
import ProgressBar from '../../../progress/ProgressBar';
import ProgressProgramDisplay from '../../../progress/ProgressProgramDisplay';
import ProgressRemarks from '../../../progress/ProgressRemarks';

export default function SearchStudentProgressTab({ studentId }) {
    const { activeThemeSettings } = useSelector((state) => state.settings.theme);
    const { searchStudent } = useSelector((state) => state.search.student);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    const [programStatus, setProgramStatus] = useState(false);

    useEffect(() => {
        setProgramStatus(searchStudent?.studentProgress?.totalPoints?.achievedPoints >= searchStudent?.studentProgress?.totalPoints?.minPoints && (searchStudent?.studentProgress?.requiredPoints?.anyRequiredPrograms && searchStudent?.studentProgress?.requiredPoints?.requiredPointsStatus === "green") && (searchStudent?.studentProgress?.progressFlags?.lifestyleStatus === 2))
         
    }, [searchStudent]);

    return (
        <Grid container spacing={1} size={{ xs: 12 }}>
            {programStatus && <Grid size={{ xs: 12 }} sx={{ display: 'flex' }}>
                <Card elevation={0} sx={{ ...CardMedium, display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', color: programStatus ? 'success.main' : 'error.main' }}>
                        {resourceFinal[appLanguage].messages.PROGRESS_PROGRAM_COMPLETED}
                    </Typography>
                </Card>
            </Grid>}
            {searchStudent?.studentProgress &&
                <Grid size={{ xs: 12, md: activeThemeSettings.flagsEnabled ? 8 : 12 }} sx={{ display: 'flex' }}>
                    <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flex: '1 1 auto', }}>
                            <Box sx={{ flex: '1 1 auto' }}>
                                <ProgressBar progressPoints={searchStudent?.studentProgress?.totalPoints} legendDisplay={true} barHeight={180} />
                            </Box>
                        </Box>
                    </Card>
                </Grid>}
            {(activeThemeSettings.flagsEnabled && searchStudent?.studentProgress) &&
                <Grid size={{ xs: 12, md: 4 }} sx={{ display: 'flex' }}>
                    <Card elevation={0} sx={{ ...CardMedium }}>
                        <FlagDisplay progressFlags={searchStudent?.studentProgress?.progressFlags} requiredPoints={searchStudent?.studentProgress?.requiredPoints} xsIconSize={60} mdIconSize={60} compact={false} />
                    </Card>
                </Grid>}
            {searchStudent?.studentProgress &&
                <Grid size={{ xs: 12 }} sx={{ display: 'flex' }}>
                    <Card elevation={0} sx={{ ...CardMedium }}>
                        <Typography variant="h6" sx={{ ...CardHeader }}>{resourceFinal[appLanguage].messages.CURRENT_CLINICS}</Typography>
                        <ProgressActivities progressActivities={searchStudent?.studentProgress?.progressActivities} employeeMode employeeStudentId={studentId} />
                    </Card>
                </Grid>}
            {searchStudent?.studentProgress?.progressRemarks.remarksCount > 0 &&
                <Grid size={{ xs: 12 }} sx={{ display: 'flex' }}>
                    <Card elevation={0} sx={{ ...CardMedium }}>
                        <Typography variant="h6" sx={{ ...CardHeader }}>{resourceFinal[appLanguage].words.REMARKS} ({searchStudent?.studentProgress?.progressRemarks.remarksCount})</Typography>
                        <ProgressRemarks remarks={searchStudent?.studentProgress?.progressRemarks.remarks} />
                    </Card>
                </Grid>}
            {searchStudent?.studentProgress &&
                <Grid size={{ xs: 12 }}>
                    <ProgressProgramDisplay progressProgram={searchStudent?.studentProgress?.progressProgram} />
                </Grid>}
            {!searchStudent?.studentProgress &&
                <Grid size={{ xs: 12 }}>
                    <Card elevation={0} sx={{ ...CardMedium }}>
                        <Typography variant="body1" textAlign="center">{resourceFinal[appLanguage].messages.SEARCH_STUDENT_NO_CONTENT}</Typography>
                        <Typography variant="body1" textAlign="center">{resourceFinal[appLanguage].messages.SEARCH_STUDENT_NO_CONTENT_REASON}</Typography>
                    </Card>
                </Grid>}
        </Grid>
    );
}
