import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "../api-authorization/AuthorizeService";

export const fetchAllAttendances = createAsyncThunk(
    "/api/attendance/currentTodo",
    async ({ completed, departmentId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        var urlParams = departmentId !== null ? `api/attendance/${completed}/${departmentId}` : `api/attendance/${completed}`;
        const response = await fetch(window.Config.apiUrl + urlParams, {
            headers: new Headers({
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "GET",
            redirect: "follow",
        });

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const fetchMomentAttendance = createAsyncThunk(
    "/api/attendance/moment/momentId",
    async (momentId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/attendance/moment/${momentId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const fetchPerformanceAttendance = createAsyncThunk(
    "/api/attendance/performance/performanceId",
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/attendance/performance/${performanceId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const postAttendances = createAsyncThunk(
    "/api/attendance/moment/post",
    async (newAttendances, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/attendance/moment`, {
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "POST",
            redirect: "follow",
            body: JSON.stringify(newAttendances),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const fetchEmployeesForAuthorization = createAsyncThunk(
    "/api/attendance/employees/new/performanceId",
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/attendance/employees/new/${performanceId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const postEmployeeAuthorizations = createAsyncThunk(
    "/api/attendance/post/authorizeEmployee",
    async (employeeAuthorizations, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/attendance/authorizeEmployee`, {
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: !token ? {} : `Bearer ${token}`,
            }),
            method: "POST",
            redirect: "follow",
            body: JSON.stringify(employeeAuthorizations),
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        }
    }
);

export const fetchExistingAuthorizations = createAsyncThunk(
    "/api/attendance/employees/existing/performanceId",
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/attendance/employees/existing/${performanceId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const deleteAuthorization = createAsyncThunk(
    '/api/attendance/delete',
    async ({ performanceId, employeeId }, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(window.Config.apiUrl + `api/attendance/${performanceId}/${employeeId}`, {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': !token ? {} : `Bearer ${token}`
            }),
            method: 'DELETE',
            redirect: 'follow',
        });

        if (!response.ok) {
            return rejectWithValue("Er is iets fout gegaan...");
        } else {
            return response.json();
        };
    }
);

export const fetchAttendanceList = createAsyncThunk(
    "/api/attendance/list/performanceId",
    async (performanceId, { rejectWithValue }) => {
        const token = await authService.getAccessToken();
        const response = await fetch(
            window.Config.apiUrl + `api/attendance/list/${performanceId}`,
            {
                headers: new Headers({
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: !token ? {} : `Bearer ${token}`,
                }),
                method: "GET",
                redirect: "follow",
            }
        );

        if (!response.ok) {
            const errorCode = await response.json();
            return rejectWithValue(errorCode);
        } else {
            return response.json();
        }
    }
);

export const attendanceSlice = createSlice({
    name: "attendance",
    initialState: {
        attendances: null,
        attendancesStatus: null,
        attendancesPerformance: null,
        attendancesPerformanceStatus: null,
        attendanceMoment: null,
        attendanceMomentStatus: null,
        postAttendanceStatus: null,
        employeesForAuthorization: null,
        employeesForAuthorizationStatus: null,
        existingAuthorizations: null,
        existingAuthorizationsStatus: null,
        postEmployeeAuthorizationsStatus: null,
        deleteAuthorizationsStatus: null,
        showAuthorizationFailed: false,
        attendanceList: null,
        attendanceListStatus: null
    },
    reducers: {
        setFailedAuthorizations: (state, action) => {
            state.showAuthorizationFailed = action.payload;
        },
        setAllPresents: (state, action) => {
            state.attendanceMoment.attendances.forEach((item) => (item.present = action.payload));
        },
        setPresent: (state, action) => {
            const index = state.attendanceMoment.attendances.findIndex(
                (item) => item.studentId === action.payload.id
            );
            state.attendanceMoment.attendances[index].present = action.payload.value;
        },
        setDispensation: (state, action) => {
            const index = state.attendanceMoment.attendances.findIndex(
                (item) => item.studentId === action.payload.id
            );
            state.attendanceMoment.attendances[index].dispensation = action.payload.value;
        },
        setWorkedHours: (state, action) => {
            const index = state.attendanceMoment.attendances.findIndex(
                (item) => item.studentId === action.payload.id
            );
            state.attendanceMoment.attendances[index].workedHours = action.payload.value;
        },
        setRemark: (state, action) => {
            const index = state.attendanceMoment.attendances.findIndex(
                (item) => item.studentId === action.payload.id
            );
            state.attendanceMoment.attendances[index].remark = action.payload.value;
        },
        setAllCreatedBy: (state, action) => {
            state.attendanceMoment.attendances.forEach((item) => (item.createdBy = action.payload));
        },
        setAllModifiedBy: (state, action) => {
            state.attendanceMoment.attendances.forEach((item) => (item.modifiedBy = action.payload));
        },
        resetAttendanceStatus: (state) => {
            state.attendancesStatus = null;
            state.attendanceMomentStatus = null;
            state.postAttendanceStatus = null;
            state.postEmployeeAuthorizationsStatus = null;
            state.employeesForAuthorizationStatus = null;
        },
    },
    extraReducers: (builder) => {
        // Get all attendances
        builder
            .addCase(fetchAllAttendances.pending, (state) => {
                state.attendancesStatus = "loading";
            })
            .addCase(fetchAllAttendances.fulfilled, (state, action) => {
                state.attendancesStatus = "success";
                state.attendances = action.payload;
            })
            .addCase(fetchAllAttendances.rejected, (state) => {
                state.attendancesStatus = "failed";
            });

        // Get moment attendance
        builder
            .addCase(fetchMomentAttendance.pending, (state) => {
                state.attendanceMomentStatus = "loading";
            })
            .addCase(fetchMomentAttendance.fulfilled, (state, action) => {
                state.attendanceMomentStatus = "success";
                state.attendanceMoment = action.payload;
            })
            .addCase(fetchMomentAttendance.rejected, (state) => {
                state.attendanceMomentStatus = "failed";
            });

        // Get performance attendances
        builder
            .addCase(fetchPerformanceAttendance.pending, (state) => {
                state.attendancesPerformanceStatus = "loading";
            })
            .addCase(fetchPerformanceAttendance.fulfilled, (state, action) => {
                state.attendancesPerformanceStatus = "success";
                state.attendancesPerformance = action.payload;
            })
            .addCase(fetchPerformanceAttendance.rejected, (state) => {
                state.attendancesPerformanceStatus = "failed";
            });

        // Post attendances
        builder
            .addCase(postAttendances.pending, (state) => {
                state.postAttendanceStatus = "loading";
            })
            .addCase(postAttendances.fulfilled, (state) => {
                state.postAttendanceStatus = "success";
            })
            .addCase(postAttendances.rejected, (state) => {
                state.postAttendanceStatus = "failed";
            });

        // Get employees for authorization
        builder
            .addCase(fetchEmployeesForAuthorization.pending, (state) => {
                state.employeesForAuthorizationStatus = "loading";
            })
            .addCase(fetchEmployeesForAuthorization.fulfilled, (state, action) => {
                state.employeesForAuthorizationStatus = "success";
                state.employeesForAuthorization = action.payload;
            })
            .addCase(fetchEmployeesForAuthorization.rejected, (state) => {
                state.employeesForAuthorizationStatus = "failed";
            });

        // Post employee authorizations
        builder
            .addCase(postEmployeeAuthorizations.pending, (state) => {
                state.postEmployeeAuthorizationsStatus = "loading";
            })
            .addCase(postEmployeeAuthorizations.fulfilled, (state) => {
                state.postEmployeeAuthorizationsStatus = "success";
            })
            .addCase(postEmployeeAuthorizations.rejected, (state) => {
                state.postEmployeeAuthorizationsStatus = "failed";
            });

        // Get existing authorizations
        builder
            .addCase(fetchExistingAuthorizations.pending, (state) => {
                state.existingAuthorizationsStatus = "loading";
            })
            .addCase(fetchExistingAuthorizations.fulfilled, (state, action) => {
                state.existingAuthorizationsStatus = "success";
                state.existingAuthorizations = action.payload;
            })
            .addCase(fetchExistingAuthorizations.rejected, (state) => {
                state.existingAuthorizationsStatus = "failed";
            });

        // Deauthorize employee 
        builder
            .addCase(deleteAuthorization.pending, (state) => {
                state.deleteAuthorizationsStatus = "loading";
            })
            .addCase(deleteAuthorization.fulfilled, (state) => {
                state.deleteAuthorizationsStatus = "success";
            })
            .addCase(deleteAuthorization.rejected, (state) => {
                state.deleteAuthorizationsStatus = "failed";
            });

        // Get attendance list
        builder
            .addCase(fetchAttendanceList.pending, (state) => {
                state.attendanceListStatus = "loading";
            })
            .addCase(fetchAttendanceList.fulfilled, (state, action) => {
                state.attendanceListStatus = "success";
                state.attendanceList = action.payload;
            })
            .addCase(fetchAttendanceList.rejected, (state) => {
                state.attendanceListStatus = "failed";
            });
    },
});

export const {
    setAllPresents,
    setPresent,
    setDispensation,
    setWorkedHours,
    setRemark,
    setAllCreatedBy,
    setAllModifiedBy,
    resetAttendanceStatus,
    setFailedAuthorizations
} = attendanceSlice.actions;

export default attendanceSlice.reducer;
