import { Box, Button, IconButton, Modal, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography, alpha } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { MainBorderRadius, PageHeader, TableHeader } from '../../../app/AppStyles';
import { SearchKeyFilter } from '../../../app/AppUtils';
import BackButton from '../../subcomponents/BackButton';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import ListItemCard from '../../subcomponents/ListItemCard';
import PageLayout from '../../subcomponents/PageLayout';
import SearchField from '../../subcomponents/SearchField';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import { deleteSubprogramActivity, getSubprogramActivities, resetSubprogramActivityStatus, selectSubprogramActivity, setFailedSubprogramActivity } from '../subprogram/subprogramManagementSlice';
import SubprogramActivityAddCard from './SubprogramActivityAddCard';
import SubprogramActivityEditCard from './SubprogramActivityEditCard';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

export default function SubprogramPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const textRef = useRef(null);

    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [filter, setFilter] = useState("");

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedSubprogram, subprogramActivities, subprogramActivitiesStatus, deleteSubprogramActivitiesStatus } = useSelector((state) => state.management.subprogram);

    useEffect(() => {
        if (selectedSubprogram)
            dispatch(getSubprogramActivities(selectedSubprogram.id));
        else
            navigate("/programmabeheer")
    }, [dispatch, navigate, selectedSubprogram])

    useEffect(() => {
        if (deleteSubprogramActivitiesStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetSubprogramActivityStatus());
            dispatch(getSubprogramActivities(selectedSubprogram.id));
        } else if (deleteSubprogramActivitiesStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetSubprogramActivityStatus());
        };
    }, [dispatch, resourceFinal, appLanguage, selectedSubprogram, deleteSubprogramActivitiesStatus]);

    const handleSearchChange = (e) => {
        let value = e.target.value;
        let timeout = null;

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            setFilter(value);
        }, 600);
    };

    const handleFilterResult = (item) => {
        return SearchKeyFilter(item, ["activityName", "activityDescription"], filter);
    }

    const newClick = () => {
        dispatch(selectSubprogramActivity(null));
        dispatch(resetSubprogramActivityStatus());
        dispatch(setFailedSubprogramActivity(false));
        setOpenNew(true);
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(selectSubprogramActivity(null));
        dispatch(resetSubprogramActivityStatus());
        dispatch(setFailedSubprogramActivity(false));
    };

    const callbackModalEdit = () => {
        setOpenEdit(false);
        dispatch(selectSubprogramActivity(null));
        dispatch(resetSubprogramActivityStatus());
        dispatch(setFailedSubprogramActivity(false));
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - subprogramActivities?.filter(handleFilterResult).length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const editClick = (subprogramActivity) => {
            dispatch(selectSubprogramActivity(subprogramActivity));
            setOpenEdit(true);
        };

        const deleteClick = (activityId) => {
            dispatch(deleteSubprogramActivity([activityId]));
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? subprogramActivities.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : subprogramActivities.filter(handleFilterResult))?.map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell align="left">{item.activityName ?? "-"}</TableCell>
                            <Tooltip title={item.activityDescription} placement="top-start">
                                <TableCell align="left" sx={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "300px",
                                    minWidth: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{item.activityDescription ?? "-"}</TableCell>
                            </Tooltip>
                            <TableCell align="center" padding="checkbox">{item.minSubscriptions ?? "-"}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.maxSubscriptions ?? "-"}</TableCell>
                            <TableCell align="center" padding="checkbox">{item.maxAbsence ?? "-"}</TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => editClick(item)}><EditIcon color="secondary" /></IconButton></TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${item.activityName} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => deleteClick(item.id)} />
                            </TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? subprogramActivities.filter(handleFilterResult).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : subprogramActivities.filter(handleFilterResult))?.length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[10, 25, 50,
                                {
                                    label: subprogramActivities?.filter(handleFilterResult).length < 100 ? resourceFinal[appLanguage].words.ALL : '100',
                                    value: subprogramActivities?.filter(handleFilterResult).length < 100 ? -1 : 100
                                }
                            ]}
                            count={subprogramActivities?.filter(handleFilterResult).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <>
            <PageLayout content={
                <Stack spacing={1} sx={{ flex: '1 1 auto', bgcolor: 'white.light', borderRadius: MainBorderRadius, p: 0.5 }}>
                    <Grid container spacing={1}>
                        <Grid size={{ xs: 12 }}>
                            <ListItemCard altBgColor={{ bgcolor: theme => alpha(theme.palette.primary.light, 0.3) }}
                                primaryText={selectedSubprogram?.name}
                                secondaryText={resourceFinal[appLanguage].words.SUBPROGRAM}
                            />
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                            <ListItemCard altBgColor={{ bgcolor: theme => alpha(theme.palette.primary.light, 0.3) }}
                                primaryText={selectedSubprogram?.description ? selectedSubprogram?.description : "-"}
                                secondaryText={resourceFinal[appLanguage].words.DESCRIPTION}
                            />
                        </Grid>
                    </Grid>
                    <ContentLoader successCondition={subprogramActivitiesStatus === "success"} errorCondition={subprogramActivitiesStatus === "failed"} content={
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                                <SearchField func={handleSearchChange} textRef={textRef} />
                                <Typography variant="h6" sx={{ ...PageHeader, pb: 0 }}>{resourceFinal[appLanguage].words.LINKED2} {resourceFinal[appLanguage].words.ACTIVITIES.toLowerCase()}</Typography>
                                <Button variant="outlined" onClick={newClick} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                            </Box>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.SUBSCRIPTIONS_MIN}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.SUBSCRIPTIONS_MAX}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ABSENCE_MAX}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableFragment />
                                </Table>
                            </TableContainer>
                        </Box>
                    } />
                </ Stack>
            } contentRight={
                <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                    <BackButton desktop />
                </Box>
            } />

            <Modal open={openEdit} onClose={callbackModalEdit}>
                <>
                    <SubprogramActivityEditCard handleClose={callbackModalEdit} />
                </>
            </Modal>

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <SubprogramActivityAddCard handleClose={callbackModalNew} />
                </>
            </Modal>
        </>
    );
}