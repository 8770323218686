import { Box, Card, CardHeader, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { green, yellow } from '@mui/material/colors';
import Grid from '@mui/material/Grid2';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardMedium, TableHeader } from '../../app/AppStyles';
import { GetDate, GetTime } from '../../app/AppUtils';
import ContentLoader from '../subcomponents/ContentLoader';
import PageLayout from '../subcomponents/PageLayout';
import { fetchProgressAttendances } from './progressSlice';

import CircleIcon from '@mui/icons-material/Circle';

export default function ProgressAttendancePage() {
    const dispatch = useDispatch();

    const { progressAttendances, progressAttendancesStatus } = useSelector((state) => state.progress);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { appSettings } = useSelector((state) => state.settings.app);
    const { activeDepartment } = useSelector((state) => state.settings.theme);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    useEffect(() => {
        var departmentId = siomSettings?.useMultipleDepartments && activeDepartment ? activeDepartment[0]?.id : null
        dispatch(fetchProgressAttendances(departmentId));
    }, [siomSettings.useMultipleDepartments, activeDepartment]);

    return (
        <PageLayout title={resourceFinal[appLanguage].words.ATTENDANCE} content={
            <ContentLoader successCondition={progressAttendancesStatus === "success"} errorCondition={progressAttendancesStatus === "failed"} content={
                <Card elevation={0} sx={{ ...CardMedium, display: 'flex' }}>
                    <TableContainer sx={{ display: { xs: 'none', md: 'table' } }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ACTIVITY}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.MOMENT} {resourceFinal[appLanguage].words.DATE} </TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DISPENSATION}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PRESENCE}</TableCell>
                                    <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.REMARK}</TableCell>
                                    {appSettings?.appVersion === 2 && <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.HOURS}</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {progressAttendances?.map((item, index) =>
                                    <TableRow key={index}>
                                        <TableCell align="left">{item.activityName}</TableCell>
                                        <TableCell align="center">{GetDate(item.momentDate)} {GetTime(item.momentDate)}</TableCell>
                                        <TableCell align="center"><Checkbox disabled checked={item.dispensation} /></TableCell>
                                        <TableCell align="center">
                                            <CircleIcon sx={{
                                                color: item.dispensation ? green[500] : item.present ? green[500] : yellow[700]
                                            }} />
                                        </TableCell>
                                        <TableCell align="center">{item.remark}</TableCell>
                                        {appSettings?.appVersion === 2 && <TableCell align="center">{item.workedHours}</TableCell>}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box>
                        <Grid container spacing={0.5} sx={{ display: { xs: 'flex', md: 'none' } }}>
                            {progressAttendances?.map((item, index) =>
                                <Grid size={{ xs: 12 }} key={index}>
                                    <Card elevation={0}>
                                        <CardHeader
                                            avatar={<CircleIcon sx={{ color: item.present ? green[500] : yellow[700] }} />}
                                            title={item.activityName}
                                            subheader={`${GetDate(item.momentDate)} ${GetTime(item.momentDate)}`}
                                        />
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Card>
            } />
        } bottomBar />
    );
}
