import { Box, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItemBackground, PageHeader, TableHeader } from '../../../app/AppStyles';
import ContentLoader from '../../subcomponents/ContentLoader';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';

import DeleteIcon from '@mui/icons-material/Delete';
import { deleteAuthorization, fetchExistingAuthorizations } from '../../attendance/attendanceSlice';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';

export default function AuthorizationsDisplay({ performanceId }) {
    const dispatch = useDispatch();

    const { existingAuthorizations, existingAuthorizationsStatus, deleteAuthorizationsStatus } = useSelector((state) => state.attendance);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    useEffect(() => {
        if (deleteAuthorizationsStatus === "success")
            dispatch(fetchExistingAuthorizations(performanceId));
    }, [deleteAuthorizationsStatus]);

    const SubscriptionsFragment = () => {
        const [page, setPage] = React.useState(0);
        const [rowsPerPage, setRowsPerPage] = React.useState(5);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - existingAuthorizations.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const deleteClick = (employeeId) => {
            dispatch(deleteAuthorization({ performanceId: performanceId, employeeId: employeeId }));
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? existingAuthorizations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : existingAuthorizations).map((item, index) =>
                        <TableRow key={index}>
                            <TableCell align="center">{item.number}</TableCell>
                            <TableCell align="left">{item.fullName}</TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon />} buttonColor="error" message={`${item.fullName} ${resourceFinal[appLanguage].words.DEAUTHORIZE.toLowerCase()}?`} confirmAction={() => deleteClick(item.employeeId)} />
                            </TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? existingAuthorizations?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : existingAuthorizations)?.length === 0 && <TableRow><TableCell colSpan={4}>{resourceFinal[appLanguage].messages.RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 53 * emptyRows }}><TableCell colSpan={6} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 25]}
                            count={existingAuthorizations ? existingAuthorizations.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <ContentLoader successCondition={existingAuthorizationsStatus === "success"} errorCondition={existingAuthorizationsStatus === "failed"} content={
            <Box sx={{ p: 0.5, ...ListItemBackground }}>
                <Toolbar>
                    <Typography
                        sx={{ ...PageHeader }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                    >
                        {resourceFinal[appLanguage].words.ATTENDANCE} {resourceFinal[appLanguage].words.AUTHORIZATIONS.toLowerCase()}
                    </Typography>
                </Toolbar>
                <TableContainer>
                    <Table aria-labelledby="tableTitle">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NUMBER}</TableCell>
                                <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <SubscriptionsFragment />
                    </Table>
                </TableContainer>
            </Box>
        } />
    );
}
